import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { filter, find, some } from 'lodash';
import { CpIcon } from 'canopy-styleguide!sofe';
import { NavContent, TopnavSecondary } from 'primary-navbar!sofe';
import { SecondaryNavigationContext } from '../secondary-navigation-context';
import { CanopyPaymentsContext } from '../../common/canopy-payments-context';
import { useHasAccess } from 'cp-client-auth!sofe';

import styles from './secondary-nav.styles.css';

const BillingSecondaryNav = props => {
  const { hasCanopyPayments, hasAdyen, teamCanKeyInCards } = useContext(CanopyPaymentsContext);
  const canViewDeposits = useHasAccess('clients_not_assigned');

  const globalTabs = [
    { key: 'dashboard', label: 'Billing Dashboard' },
    { key: 'productivity', label: 'Productivity', link: '/time/productivity' },
    { key: 'wip', label: 'WIP Report', showActions: true, showKebab: true },
    { key: 'reports', label: 'Reports', link: '/time/reports', exclude: 'billing' },
    { key: 'invoices', label: 'Invoices', exclude: 'editor' },
    { key: 'payments', label: 'Payments', link: '/billing/payments' },
    { key: 'credits', label: 'Credits', link: '/billing/credits' },
    { key: 'time', label: 'Time Entries', exclude: 'productivity' },
    { key: 'statements', label: 'Statements' },
    { key: 'reports', label: 'Reports', link: '/billing/reports', exclude: 'time' },
  ];

  const subTabs = [
    { key: 'collected', parent: 'payments', label: 'Collected' },
    { key: 'upcoming', parent: 'payments', label: 'Upcoming', enabled: hasCanopyPayments },
    ...((hasAdyen && teamCanKeyInCards) || !hasAdyen
      ? [
          {
            key: 'recurring',
            parent: 'payments',
            label: 'Recurring',
            enabled: hasCanopyPayments,
          },
        ]
      : []),
    ...(canViewDeposits
      ? [{ key: 'deposits', parent: 'payments', label: 'Deposits', enabled: hasCanopyPayments }]
      : []),
    { key: 'refunds', parent: 'payments', label: 'Refunds', enabled: hasCanopyPayments },
    ...(!hasAdyen && canViewDeposits
      ? [
          { key: 'credit-card-summary', parent: 'payments', label: 'Credit Card', enabled: hasCanopyPayments },
          { key: 'ach-summary', parent: 'payments', label: 'ACH', enabled: hasCanopyPayments },
        ]
      : []),
  ];

  const location = useLocation();
  const [customComponent, setCustomComponent] = useState(null);
  const [showArrowBack, setShowArrowBack] = useState(false);
  const [show, setShow] = useState(true);
  const [title, setTitle] = useState();
  const [showSubTabs, setShowSubTabs] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [activeSubTab, setActiveSubTab] = useState();
  const [actions, setActions] = useState([]);
  const [showKebabMenu, setShowKebabMenu] = useState();
  const [kebabMenu, setKebabMenu] = useState(() => {});

  useEffect(() => {
    if (location) {
      const isClient = location.pathname.includes('client');

      if (!isClient) {
        const activeTab = getActiveTab();
        setActiveTab(activeTab);
        setTitle(activeTab ? { link: activeTab.link, text: activeTab.label } : {});

        if (activeTab) {
          const hasSubTabs = some(subTabs, tab => tab.parent === activeTab.key && tab.enabled !== false);
          setShowSubTabs(hasSubTabs);
          setActiveSubTab(getActiveSubTab(activeTab.key));

          if (!activeTab.showActions) {
            setActions([]);
          }
          setShowKebabMenu(activeTab.showKebab);
        }
      }
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'getActiveSubTab', 'getActiveTab', 'location', and 'subTabs'

  const getActiveTab = () => {
    return find(globalTabs, tab => {
      const parts = location.pathname.split('/');
      return parts.includes(tab.key) && !parts.includes(tab.exclude);
    });
  };

  const getActiveSubTab = parentTab => {
    return find(subTabs, tab => {
      const parts = location.pathname.split('/');
      return tab.parent === parentTab && parts.includes(tab.key);
    });
  };

  const renderSubTabs = (
    <div className={styles.subTabs}>
      <ul>
        {activeTab &&
          filter(subTabs, subTab => subTab.parent === activeTab.key && subTab.enabled !== false).map(subTab => (
            <li key={subTab.key} className={activeSubTab && activeSubTab.key === subTab.key ? styles.active : ''}>
              <Link to={subTab.key}>{subTab.label}</Link>
            </li>
          ))}
      </ul>
    </div>
  );

  const renderActions = (
    <ul className="cp-flex cp-m-0" style={{ listStyle: 'none' }}>
      {actions.map(action => (
        <li key={action.id}>{action.render()}</li>
      ))}
    </ul>
  );

  const renderSecondaryNav = (
    <TopnavSecondary className="cps-bg-color-white">
      <div className="cp-flex-spread-center" style={{ height: '56px' }}>
        <div>
          {customComponent ? (
            <>{customComponent}</>
          ) : (
            title && (
              <div
                className={`cps-wt-semibold cp-ml-16 ${title.subtext ? 'cps-subheader-sm' : 'cps-subheader'} ${
                  styles.title
                }`}
              >
                {title.link ? (
                  <Link to={{ pathname: title.link, state: title.state }}>
                    <div className="cp-flex-center">
                      {showArrowBack && (
                        <div className="cp-mr-12">
                          <CpIcon name="caret-large-left" />
                        </div>
                      )}
                      <div>
                        {title.text}
                        {title.subtext && <div className="cps-caption cps-color-monsoon">{title.subtext}</div>}
                      </div>
                    </div>
                  </Link>
                ) : (
                  title.text
                )}
              </div>
            )
          )}
        </div>
        {showSubTabs && renderSubTabs}
        <div className="cp-flex-center">
          {actions?.length > 0 && renderActions}
          {showKebabMenu && kebabMenu}
        </div>
      </div>
    </TopnavSecondary>
  );

  return (
    <SecondaryNavigationContext.Provider
      value={{
        showSecondaryNav: () => setShow(true),
        hideSecondaryNav: () => setShow(false),
        setCustomTitle: title => setTitle(title),
        setCustomComponent: setCustomComponent,
        setShowArrowBack: setShowArrowBack,
        setActions: setActions,
        setKebabMenu: setKebabMenu,
      }}
    >
      {show && (
        <NavContent hasTopnavSecondary={true} clientMenuPossible={false} style={{ padding: 0 }}>
          {renderSecondaryNav}
        </NavContent>
      )}
      {props.children}
    </SecondaryNavigationContext.Provider>
  );
};

export default BillingSecondaryNav;
