import React, { useState, useEffect } from 'react';
import { find, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { useHasAccess } from 'cp-client-auth!sofe';
import { luxonDefaultDateFormat } from 'src/billing-helpers';
import { convertToCurrencyText, fetchPaymentNumber, fetchCredit } from 'src/payments/payments.helper';
import { showCreditOverlay } from '../../../credits/components/credit-overlay/credit-overlay-wrapper.component';

import styles from './invoice-history.styles.css';

export const InvoiceHistory = ({ invoice, onClose }) => {
  const [invoicePaymentData, setInvoicePaymentData] = useState([]);

  const allowPayments = useHasAccess('billing_payments');
  const allowCredits = useHasAccess('billing_credits');

  useEffect(() => {
    if (allowPayments === undefined || allowCredits === undefined) {
      return;
    }
    let creditIdsToFetch = [];
    let paymentIdsToFetch = [];
    let dataToFetch = [];
    for (const historyItem of invoice.history) {
      const paymentId = historyItem.value?.payment_id;
      const creditId = historyItem.value?.credit_id;
      const deleted = historyItem.value?.payment_deleted;

      if (!deleted && (paymentId || creditId)) {
        if (allowPayments && historyItem.name.includes('payment')) {
          paymentIdsToFetch.push(paymentId);
        } else if (allowCredits && historyItem.name.includes('credit')) {
          creditIdsToFetch.push(creditId);
        }
      }
    }

    dataToFetch = [
      ...uniq(paymentIdsToFetch).map(id => fetchPaymentNumber(id)),
      ...uniq(creditIdsToFetch).map(id => fetchCredit(id)),
    ];
    forkJoin(dataToFetch).subscribe(setInvoicePaymentData);
  }, [invoice, allowPayments, allowCredits]);

  const viewPayment = paymentId => {
    window.location = `#/billing/payments/collected/${paymentId}/receipt`;
    onClose();
  };

  const viewCredit = overlayProps => {
    showCreditOverlay(overlayProps);
    onClose();
  };

  const messages = {
    updated: '{$type} edited',
    created: '{$type} created',
    sent: '{$type} sent',
    archived: '{$type} archived',
    unarchived: '{$type} unarchived',
    deleted: '{$type} deleted',
    undeleted: '{$type} undeleted',
    printed: '{$type} printed',
    downloaded: '{$type} downloaded',
    credit_invoice_added: 'A credit of {$value} was applied. {$credit}',
    credit_invoice_removed: 'A credit of {$value} was deleted. {$credit}',
    credit_invoice_changed: 'Credit changed to {$value}. {$credit}',
    credit_invoice_refunded: 'A credit of {$refundValue} was refunded. {$credit}',
    credit_invoice_returned: 'A credit of {$refundValue} was returned. {$credit}',
    credit_invoice_updated: 'A previously applied credit was edited to be {$value}. {$credit}',
    payment_invoice_added: 'Payment of {$value} was added. {$payment}',
    payment_invoice_removed: 'Payment of {$value} was deleted. {$payment}',
    payment_invoice_changed: 'Payment changed to {$value}. {$payment}',
    payment_invoice_refunded: 'Payment of {$refundValue} was refunded. {$payment}',
    payment_invoice_returned: 'Payment of {$refundValue} was returned. {$payment}',
    payment_invoice_declined: 'Payment declined. {$payment}',
    payment_schedule_added: 'Payment of {$value} was scheduled for {$date}',
    payment_schedule_updated: 'Scheduled Payment changed to {$value} on {$date}',
    payment_schedule_deleted: 'Scheduled Payment of {$value} was deleted',
    payment_credit_added: 'Credit of {$value} was added from Payment {$payment}',
    late_fee_applied: 'A late fee of {$value} was applied',
  };

  const getHistoryMessage = ({ name, value }, type, payment, credit, onOpenItem) => {
    let message = messages[name];
    if (name === 'payment_invoice_added' && value?.recurrence) {
      message = 'Scheduled ' + message.replace('added', 'processed');
    }
    const parts = message?.split(/\{([^}]+)\}/g);
    return parts?.map(part => {
      if (part === '$type') {
        return type;
      } else if (part === '$value') {
        return convertToCurrencyText(value.amount);
      } else if (part === '$refundValue') {
        return convertToCurrencyText(value.refund_display_amount);
      } else if (part === '$date') {
        return DateTime.fromISO(value.recurrence.next_occurrence).toLocaleString(DateTime.DATE_SHORT);
      } else if (part === '$payment') {
        return (
          payment?.number &&
          (payment.active ? (
            <a onClick={() => onOpenItem(payment.id)}>#{payment.number}</a>
          ) : (
            <span>#{payment.number}</span>
          ))
        );
      } else if (part === '$credit') {
        return (
          credit?.number &&
          (credit.active ? (
            <a onClick={() => onOpenItem(credit.id)}>#{credit.number}</a>
          ) : (
            <span>#{credit.number}</span>
          ))
        );
      }

      return part;
    });
  };

  return (
    <div className={styles.invoiceHistory}>
      <div className="cps-body cps-wt-semibold cp-pb-12">Invoice History</div>
      {invoice.history && (
        <ul>
          {invoice.history
            .sort((a, b) => b.date - a.date)
            .map((item, index) => {
              const isPayment = item.name.includes('payment');
              const isCredit = item.name.includes('credit');
              const paymentId = isPayment ? item?.value?.payment_id : item?.value?.credit_id;
              const overlayProps = {
                overlayData: { creditId: paymentId },
              };

              const payment = (invoicePaymentData &&
                (isPayment || isCredit) &&
                find(invoicePaymentData, ['id', paymentId])) || {
                active: false,
              };

              const historyMessage = getHistoryMessage(
                item,
                invoice.status === 'draft' ? 'Draft' : `${item?.value?.bulk_invoice_id ? 'Bulk ' : ''}Invoice`,
                isPayment ? payment : false,
                isCredit ? payment : false,
                isPayment ? () => viewPayment(payment.id) : () => viewCredit(overlayProps)
              );

              return (
                historyMessage && (
                  <li key={index}>
                    <div style={{ width: '115px' }}>{DateTime.fromISO(item.date).toFormat(luxonDefaultDateFormat)}</div>
                    <div>
                      {historyMessage}
                      {'  '}
                      {item.performed_by_name && (
                        <span className="cps-caption cp-color-app-secondary-text" style={{ fontStyle: 'italic' }}>
                          by {item.performed_by_name}
                        </span>
                      )}
                    </div>
                  </li>
                )
              );
            })}
        </ul>
      )}
    </div>
  );
};
