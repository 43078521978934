import React, { useState, useEffect, useRef } from 'react';
import { CpTooltip } from 'canopy-styleguide!sofe';

import { convertToCurrencyText } from 'src/payments/payments.helper';

export const TextCell = value => {
  return <span>{value}</span>;
};

export const ToolTipCell = value => {
  const cellRef = useRef();
  const [overflowing, setOverflowing] = useState(false);

  useEffect(() => {
    const cs = getComputedStyle(cellRef.current.parentNode);
    const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
    if (cellRef.current.offsetWidth > cellRef.current.parentNode.offsetWidth - paddingX) {
      setOverflowing(true);
    }
  }, [cellRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies:

  return overflowing ? (
    <CpTooltip text={value}>
      <div className="cps-ellipsis">
        <span ref={cellRef}>{value}</span>
      </div>
    </CpTooltip>
  ) : (
    <span ref={cellRef}>{value}</span>
  );
};

export const NumberCell = value => {
  return value ? (
    <span>{fixNegative(value, convertToCurrencyText(Math.abs(value), false))}</span>
  ) : (
    <span>&mdash;</span>
  );
};

export const CurrencyCell = (value, colorize) => {
  return value ? (
    <span className={colorize ? (value >= 0 ? 'cps-color-success' : 'cps-color-error') : ''}>
      {fixNegative(value, convertToCurrencyText(Math.abs(value), true))}
    </span>
  ) : (
    <span>&mdash;</span>
  );
};

export const PercentCell = value => {
  return value ? <span>{fixNegative(value, `${Math.abs(value)}%`)}</span> : <span>&mdash;</span>;
};

const fixNegative = (value, displayValue) => {
  return value >= 0 ? displayValue : `(${displayValue})`;
};
