import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import ClickOutside from 'react-click-outside';
import { DateRangeSelector, CpIcon } from 'canopy-styleguide!sofe';
import styles from './filters.styles.css';

export const DateRangeFilter = ({
  dateFilter,
  onFilterChanged,
  alignRight = false,
  sortParam,
  defaultFilter,
  dateRanges,
  hideClear,
  width,
}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filter, setFilter] = useState({
    ...dateFilter,
  });
  const { after, before, filter_params } = filter;

  useEffect(() => {
    if (dateFilter.filter_params && dateFilter.filter_params !== filter_params) {
      setFilter({
        ...dateFilter,
        startDateText: dateFilter.after,
        endDateText: dateFilter.before,
      });
    }
  }, [dateFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'filter_params'

  const dateDisplay = useMemo(() => {
    if (filter_params === 'custom') {
      return `${DateTime.fromISO(after).toLocaleString(DateTime.DATE_SHORT)} - ${DateTime.fromISO(
        before ? before : after
      ).toLocaleString(DateTime.DATE_SHORT)}`;
    } else {
      return dateRanges[filter_params].label;
    }
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'after', 'before', 'dateRanges', and 'filter_params'

  const handleFilterClick = () => {
    setFilterMenuOpen(!filterMenuOpen);
  };

  const handleFilterChange = (sortParam, dates) => {
    if (!dates.after && !dates.before) {
      const reset = { ...(defaultFilter || dateFilter) };
      setFilter(reset);
      onFilterChanged(reset);
    } else {
      const formattedDates = {
        filter_params: dates.filter_params,
        after: (dates.after ? DateTime.fromJSDate(new Date(dates.after)) : DateTime.local()).toISODate(),
        before: (dates.before
          ? DateTime.fromJSDate(new Date(dates.before))
          : DateTime.fromJSDate(new Date(dates.after))
        ).toISODate(),
      };
      setFilter(formattedDates);
      onFilterChanged(formattedDates);
    }
  };

  return (
    <ClickOutside onClickOutside={() => setFilterMenuOpen(false)}>
      <div
        className={`cps-dropdown ${filterMenuOpen ? 'cps-open' : ''} ${styles.dateRangeSelector}`}
        style={{ width: width ? width : '19.6rem' }}
        onClick={handleFilterClick}
      >
        {filter && (
          <>
            <div className="cps-body" style={{ whiteSpace: 'nowrap' }}>
              {dateDisplay}
            </div>
            <CpIcon name="caret-small-down" />
            {filterMenuOpen && (
              <DateRangeSelector
                column={{ filterType: 'date-range', dateRanges, sortParam }}
                onCancel={() => setFilterMenuOpen(false)}
                filterContext={{
                  applyFilter: handleFilterChange,
                  filters: { [sortParam]: { ...dateFilter } },
                }}
                hideSortOptions={true}
                hideClear={hideClear}
                setColumnMenuShown={() => setFilterMenuOpen(false)}
                menuStyles={alignRight ? { right: '0', left: 'unset' } : {}}
              />
            )}
          </>
        )}
      </div>
    </ClickOutside>
  );
};
