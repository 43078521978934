import { pluck } from 'rxjs/operators';
import { featureEnabled } from 'feature-toggles!sofe';
import { fetchAsObservable } from 'fetcher!sofe';

export function getIntegrations() {
  return fetchAsObservable(`/wg/accounting-integrations`).pipe(pluck('integrations'));
}

export function getIncomeAccounts(id, type) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/income-accounts?third_party=${type}`).pipe(
    pluck('accounts')
  );
}

export function getCategories(id, type) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/categories?third_party=${type}`).pipe(
    pluck('categories')
  );
}

export function getPaymentAccounts(id, type) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/bank-accounts?third_party=${type}`).pipe(
    pluck('accounts')
  );
}

export function getClasses(id, type) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/classes?third_party=${type}`).pipe(pluck('classes'));
}

export function getLocations(id, type) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/locations?third_party=${type}`).pipe(pluck('locations'));
}

export function retryInvoiceSync(credentialsId, invoiceId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/invoices/${invoiceId}/retry?third_party=${type}`,
    {
      method: 'POST',
    }
  );
}

export function retryPaymentsSync(credentialsId, paymentsId, type, isRefund = false, linkedPaymentId = null) {
  const endpoint =
    isRefund && featureEnabled('toggle_gs_qbo_refund_endpoint')
      ? `/wg/accounting-integrations/${credentialsId}/refunds/${paymentsId}/retry?third_party=qbo&payment_id=${linkedPaymentId}`
      : `/wg/accounting-integrations/${credentialsId}/payments/${paymentsId}/retry?third_party=${type}`;

  return fetchAsObservable(endpoint, {
    method: 'POST',
  });
}

export function retryCreditsSync(credentialsId, creditsId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/credits/${creditsId}/retry?third_party=${type}`,
    {
      method: 'POST',
    }
  );
}
