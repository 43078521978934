import React, { useState, useEffect, useRef } from 'react';
import { fromEvent, merge } from 'rxjs';
import { findKey, get, intersectionBy, isEmpty, map, omit } from 'lodash';
import { CpButton, CpDropdown, CpIcon, CpLoader, CpTooltip } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import TimeEntryModal from '../time-entry-modal.component';
import ExpenseModal from 'src/expenses/expense-modal.component';
import { WIPReportCreateInvoiceModal } from './wip-report-create-invoice-modal.component';
import { TableHeader } from 'src/common/components/column_header/table-header.component';
import { getGroupColumns, updateWipFilters } from './wip-report.helper';
import { FilterContext } from 'src/common/filter-context';
import { getWIPTimeEntries, getWIPColumnFilter } from 'src/resources/wip-report.resources';

import styles from './wip-report.styles.css';

export const WIPReportGroup = props => {
  const pageLimit = 50;
  const {
    allowEdit,
    client,
    clientGroupId,
    containerHeight,
    containerWidth,
    fromSelectTime,
    onInvoiceEntries,
    onLinkEntries,
    showingLinkDrawer,
    onFiltersChanged,
    preSelectedEntries = [],
    tenantId,
  } = props;
  const sessionFilters = JSON.parse(sessionStorage.getItem('wipReportFilters'));
  if (!fromSelectTime && tenantId && tenantId !== sessionFilters?.tenantId) {
    sessionStorage.removeItem('wipReportFilters');
  }
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeEntries, setTimeEntries] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [maxHeight, setMaxHeight] = useState(containerHeight);
  const [selectedEntries, setSelectedEntries] = useState([...preSelectedEntries]);
  const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [timeEntryId, setTimeEntryId] = useState(null);
  const [expenseId, setExpenseId] = useState(null);
  const [filters, setFilters] = useState({});
  const getDefaultSearchFilters = () => ({
    ...(clientGroupId ? { client_group_id: clientGroupId } : { client_id: client?.id }),
    ...(props.initialTeamMemberFilter ? { assignees: { filter_params: props.initialTeamMemberFilter } } : {}),
    ...(props.initialClientOwnerFilter ? { client_owners: { filter_params: props.initialClientOwnerFilter } } : {}),
    ...(props.initialServiceItemFilter ? { service_items: { filter_params: props.initialServiceItemFilter } } : {}),
    ...(props.initialDateFilter ? { date: props.initialDateFilter } : {}),
    show_auto_linked: { filter_params: props.showAutoLinked || false },
  });
  const loadSearchFiltersFromStorage = () => {
    return !fromSelectTime && sessionFilters?.searchFilters ? sessionFilters.searchFilters : getDefaultSearchFilters();
  };
  const [searchFilters, setSearchFilters] = useState(loadSearchFiltersFromStorage());
  const [columns] = useState(getGroupColumns(false, props.showAutoLinked, clientGroupId));
  const [stickyColumns] = useState(getGroupColumns(true, props.showAutoLinked));
  const [totals, setTotals] = useState({});
  const tableRef = useRef();

  useEffect(() => {
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const expenseEvents = fromEvent(window, 'billing-ui::expense-saved');
    const subs = merge(timeEntryEvents, invoiceEvents, expenseEvents).subscribe(() => {
      setRefetch(true);
    });
    return () => subs.unsubscribe();
  }, []);

  useEffect(() => {
    if (!client && !clientGroupId) return;
    const sessionFilters = loadSearchFiltersFromStorage();
    if (!fromSelectTime && sessionFilters) {
      updateWipFilters('searchFilters', sessionFilters, setSearchFilters, tenantId, fromSelectTime);
      return;
    }
    updateWipFilters('searchFilters', getDefaultSearchFilters(), setSearchFilters, tenantId, fromSelectTime);
  }, [client, clientGroupId]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'fromSelectTime', 'getDefaultSearchFilters', 'loadSearchFiltersFromStorage', and 'tenantId'

  useEffect(() => {
    const clientIds = searchFilters?.clients?.filter_params ? { client_ids: searchFilters.clients.filter_params } : {};
    const assigneeIds = searchFilters?.assignees?.filter_params
      ? { assignee_ids: searchFilters.assignees.filter_params }
      : {};
    const clientOwnerIds = searchFilters?.client_owners?.filter_params
      ? { client_owner_ids: searchFilters.client_owners.filter_params }
      : {};
    const endDate = searchFilters?.date?.filter_params ? { end_date: searchFilters.date.before } : {};
    const startDate = searchFilters?.date?.filter_params ? { start_date: searchFilters.date.after } : {};
    const showAutoLinked = { show_auto_linked: searchFilters.show_auto_linked?.filter_params || false };
    const serviceItems = searchFilters?.service_items?.filter_params
      ? { service_item_ids: searchFilters.service_items.filter_params }
      : {};
    const taskIds = searchFilters?.tasks?.filter_params ? { task_ids: searchFilters.tasks.filter_params } : {};
    const subtaskIds = searchFilters?.subtasks?.filter_params
      ? { subtask_ids: searchFilters.subtasks.filter_params }
      : {};

    const sortedColumn = findKey(searchFilters, 'order');
    const sortBy = sortedColumn ? { sort_by: sortedColumn } : {};
    const order = sortedColumn ? { order: get(searchFilters, `${sortedColumn}.order`) } : {};
    const wipType = searchFilters?.wip_type?.filter_params ? { wip_type: searchFilters.wip_type.filter_params[0] } : {};
    const newFilterData = {
      ...((!fromSelectTime || !clientGroupId) && client?.id ? { client_ids: [client.id] } : clientIds),
      ...(clientGroupId && { client_group_id: clientGroupId }),
      ...assigneeIds,
      ...clientOwnerIds,
      ...endDate,
      ...startDate,
      ...showAutoLinked,
      ...serviceItems,
      ...taskIds,
      ...subtaskIds,
      ...sortBy,
      ...order,
      ...wipType,
    };
    setFilters(newFilterData);
    onFiltersChanged?.(newFilterData);
  }, [searchFilters]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'client.id', 'clientGroupId', 'fromSelectTime', and 'onFiltersChanged'

  useEffect(() => {
    if (!filters.client_ids && !filters.client_group_id) return;
    const subs = getWIPTimeEntries(filters, 1, pageLimit).subscribe(updateTable, handleError);
    return () => {
      subs.unsubscribe();
    };
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'updateTable'

  useEffect(() => {
    if (!refetch) return;
    const subs = getWIPTimeEntries(filters, 1, pageLimit * currentPage).subscribe(updateTable, handleError);
    return () => {
      subs.unsubscribe();
    };
  }, [refetch]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'currentPage', 'filters', and 'updateTable'

  useEffect(() => {
    if (!loadMore) return;
    const subs = getWIPTimeEntries(filters, currentPage + 1, pageLimit).subscribe(updateTable, handleError);
    return () => {
      subs.unsubscribe();
    };
  }, [loadMore]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'currentPage', 'filters', and 'updateTable'

  useEffect(() => {
    if (showingLinkDrawer) {
      onLinkEntries(selectedEntries);
    }
  }, [selectedEntries]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'onLinkEntries' and 'showingLinkDrawer'

  useEffect(() => {
    setMaxHeight(containerHeight - tableRef.current.getBoundingClientRect().top);
  }, [containerHeight]);

  const onSelectAll = evt => {
    setSelectedEntries(evt.target.checked ? [...timeEntries] : [...preSelectedEntries]);
  };

  const onSelectEntry = (evt, timeEntry) => {
    if (evt.target.checked) {
      setSelectedEntries([...selectedEntries, timeEntry]);
    } else {
      setSelectedEntries(selectedEntries.filter(entry => entry.id !== timeEntry.id));
    }
  };

  const onEditTimeEntry = timeEntryId => {
    setShowTimeEntryModal(true), setTimeEntryId(timeEntryId);
  };

  const onEditExpense = expenseId => {
    setShowExpenseModal(true), setExpenseId(expenseId);
  };

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedTimeEntries = loadMore ? [...timeEntries, ...response.rows] : response.rows;

    setTimeEntries(updatedTimeEntries);
    setSelectedEntries(intersectionBy(updatedTimeEntries, selectedEntries, 'id'));
    setTotals(response.totals);
    setCurrentPage(refetch ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setLoading(false);
    setLoadMore(false);
    setRefetch(false);
  };

  const applyFilter = (columnKey, filters) => {
    if (isEmpty(filters)) {
      updateWipFilters('searchFilters', omit(searchFilters, columnKey), setSearchFilters, tenantId, fromSelectTime);
    } else {
      const sortedColumn = findKey(searchFilters, 'order');
      const newFilters = {
        ...(filters.order && sortedColumn ? omit(searchFilters, `${sortedColumn}.order`) : searchFilters),
        [columnKey]: filters,
      };
      updateWipFilters('searchFilters', newFilters, setSearchFilters, tenantId, fromSelectTime);
    }
  };

  return (
    <div style={{ maxWidth: containerWidth }}>
      <FilterContext.Provider
        value={{
          filters: searchFilters,
          applyFilter: applyFilter,
        }}
      >
        <div className="cp-p-16" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {onLinkEntries ? (
            <CpDropdown
              contentWidth={190}
              renderTrigger={({ toggle }) => (
                <CpButton btnType="primary" disabled={!selectedEntries.length} onClick={toggle} dropdown>
                  Add to invoice
                </CpButton>
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  <button onClick={() => onInvoiceEntries(selectedEntries)}>
                    <CpIcon name="add-large" className="cp-mr-8 cp-color-app-icon" />
                    <span>Create new invoice</span>
                  </button>
                  <button onClick={() => onLinkEntries(selectedEntries)}>
                    <CpIcon name="billing-invoice" className="cp-mr-8 cp-color-app-icon" />
                    <span>Link to existing invoice</span>
                  </button>
                </div>
              )}
            />
          ) : (
            <CpButton disabled={!selectedEntries.length} onClick={() => onInvoiceEntries(selectedEntries)}>
              Invoice Entries
            </CpButton>
          )}
        </div>
        <div className={`${styles.wip}`} ref={tableRef} style={{ maxHeight: maxHeight || 'calc(100vh - 21.8rem)' }}>
          {loading ? (
            <CpLoader />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="flex items-center">
                      <label className="cps-checkbox cp-ml-24">
                        <input
                          type="checkbox"
                          onChange={onSelectAll}
                          checked={timeEntries.length > 0 && selectedEntries.length === timeEntries.length}
                          disabled={preSelectedEntries?.length > 0 && preSelectedEntries.length === timeEntries.length}
                        />
                        <span />
                      </label>
                    </div>
                  </th>
                  {map(columns, (column, columnKey) => (
                    <TableHeader
                      key={columnKey}
                      column={column}
                      getDynamicFilters={() =>
                        getWIPColumnFilter(column.sortParam, {
                          ...(client && !clientGroupId && { client_id: client.id }),
                          ...(clientGroupId && { client_group_id: clientGroupId }),
                        })
                      }
                      maxWidth={column.maxWidth}
                    />
                  ))}
                  <th>
                    <table>
                      <thead>
                        <tr className={styles.stickyColumnTable}>
                          {map(stickyColumns, (column, columnKey) => (
                            <TableHeader
                              key={columnKey}
                              allowFilterSort={true}
                              column={column}
                              maxWidth={column.maxWidth}
                            />
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </th>
                </tr>
              </thead>
              <tbody>
                {timeEntries.map((entry, entryIndex) => (
                  <tr key={entryIndex}>
                    <td>
                      <label className="cps-checkbox cp-ml-12">
                        <input
                          type="checkbox"
                          onChange={evt => onSelectEntry(evt, entry)}
                          checked={
                            !![...selectedEntries, ...preSelectedEntries].find(selected => selected.id === entry.id)
                          }
                          disabled={!!preSelectedEntries?.find(selected => selected.id === entry.id)}
                        />
                        <span />
                      </label>
                    </td>
                    {map(columns, (column, columnKey) => {
                      const editAction = columnKey === 'duration' ? onEditTimeEntry : onEditExpense;
                      const onEdit = ['duration', 'expense_number'].includes(columnKey)
                        ? allowEdit
                          ? editAction
                          : false
                        : client;
                      return (
                        <td
                          key={columnKey}
                          className="cps-ellipsis"
                          style={{ maxWidth: column.maxWidth, textAlign: column.alignment }}
                        >
                          {column.renderCell(
                            entry[columnKey === 'client_group_clients' ? 'client' : columnKey],
                            onEdit,
                            entry
                          ) || <span>&mdash;</span>}
                        </td>
                      );
                    })}
                    <td style={{ padding: '0px' }}>
                      <table>
                        <tbody>
                          <tr className={styles.stickyColumnTable}>
                            {map(stickyColumns, (column, columnKey) => (
                              <td key={columnKey} style={{ maxWidth: column.maxWidth, textAlign: column.alignment }}>
                                {column.renderCell(entry[columnKey]) ?? (
                                  <CpTooltip
                                    text={
                                      <div className="cp-text-center">
                                        Flat rates cannot be <br /> used in this calculation
                                      </div>
                                    }
                                    position="bottom-end"
                                  >
                                    <span>&mdash;</span>
                                  </CpTooltip>
                                )}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ))}
                {hasMorePages && (
                  <tr key="loadMoreCell">
                    <td className="cp-text-center cp-p-8" colSpan={clientGroupId ? 12 : 11}>
                      <CpButton
                        btnType="flat"
                        onClick={() => setLoadMore(true)}
                        disabled={loadMore}
                        showLoader={loadMore}
                      >
                        Load more
                      </CpButton>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ whiteSpace: 'nowrap' }}>{`${totals.time_entry_count} ${
                    totals.time_entry_count === 1 ? 'Entry' : 'Entries'
                  }`}</td>
                  {map(columns, (column, columnKey) => (
                    <td key={columnKey} style={{ maxWidth: column.maxWidth, textAlign: column.alignment }}>
                      {column.renderTotal(totals, columnKey === 'client_group_clients' ? 'client' : columnKey) || (
                        <span>&mdash;</span>
                      )}
                    </td>
                  ))}
                  <td style={{ padding: '0px' }}>
                    <table>
                      <tfoot>
                        <tr className={styles.stickyColumnTable}>
                          {map(stickyColumns, (column, columnKey) => (
                            <td key={columnKey} style={{ maxWidth: column.maxWidth, textAlign: column.alignment }}>
                              {column.renderTotal(totals, columnKey)}
                            </td>
                          ))}
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </FilterContext.Provider>
      <TimeEntryModal
        show={showTimeEntryModal}
        clientId={client?.id}
        onClose={() => setShowTimeEntryModal(false)}
        timeEntryId={timeEntryId}
      />
      <ExpenseModal
        externalExpenseId={expenseId}
        onClose={() => setShowExpenseModal(false)}
        onAfterClose={() => setExpenseId()}
        showModal={showExpenseModal}
      />
      <WIPReportCreateInvoiceModal
        show={showCreateInvoiceModal}
        onClose={() => setShowCreateInvoiceModal(false)}
        selectedEntries={selectedEntries}
        onInvoiceEntries={onInvoiceEntries}
        setShowCreateInvoiceModal={setShowCreateInvoiceModal}
      />
    </div>
  );
};
