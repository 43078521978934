import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getClients } from 'src/resources/clients.resources.js';

export default function TimeClientSearch({ client, onClientChanged, fixedContent, disabled }) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedClient, setSelectedClient] = useState(client);
  const [clients, setClients] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setSelectedClient(client);
  }, [client]);

  const onSelectedClientChanged = client => {
    setSelectedClient(client);
    onClientChanged(client ? { id: client.id, name: client.name } : null);
  };

  useEffect(() => {
    if (!isSearching) return;
    if (!searchValue) {
      setClients([]);
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    const clientSubs = getClients(searchValue).subscribe(results => {
      setClients(results);
      setIsSearching(false);
    }, handleError);

    return () => {
      clientSubs.unsubscribe();
    };
  }, [isSearching]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'searchValue'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchForClient = useCallback(
    debounce(() => setIsSearching(true), 400),
    []
  );
  // lint-TODO: useCallback received a function whose dependencies are unknown. Pass an inline function instead

  const searchOnChange = useCallback(search => {
    setSearchValue(search);
    debounceSearchForClient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'debounceSearchForClient'

  return (
    <CpSelectSingle
      data={clients}
      loading={isSearching}
      contentWidth="block"
      fixedContent={fixedContent}
      clearable
      placeholder="Select client"
      onChange={onSelectedClientChanged}
      searchFilter
      searchOnChange={searchOnChange}
      searchValue={searchValue}
      triggerIsBlock
      value={selectedClient}
      disabled={disabled}
    />
  );
}
