import React, { useMemo, useContext } from 'react';
import { CpIcon, CpTooltip } from 'canopy-styleguide!sofe';
import moment from 'moment';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { calculateInitialInvoiceDate, calculateFinalInvoiceDate } from './recurring-schedule-sentence.helper';
import { defaultDateFormat } from 'src/billing-helpers';
import { calculateSubTotal, calculateCreditsTotal } from './invoice-builder.helper';
import { invoiceTypes, paymentsCalc, toCurrency } from 'src/invoices/invoices.helper';

import styles from './invoice-builder.styles.css';

export const Footer = () => {
  const { invoice, recurrence, availableCredits, invoiceType } = useContext(InvoiceContext);

  const initialInvoiceDate = useMemo(() => {
    return calculateInitialInvoiceDate(recurrence);
  }, [recurrence]);

  const finalInvoiceDate = useMemo(() => {
    return calculateFinalInvoiceDate(recurrence, initialInvoiceDate);
  }, [recurrence, initialInvoiceDate]);

  const invalidEndDate = useMemo(() => {
    return (
      initialInvoiceDate &&
      finalInvoiceDate &&
      recurrence.endOption === 'On date' &&
      finalInvoiceDate.isBefore(initialInvoiceDate)
    );
  }, [recurrence.endOption, initialInvoiceDate, finalInvoiceDate]);

  const recurrenceInterval = useMemo(() => {
    let frequency = {
      Daily: `${recurrence.repeatInterval <= 1 ? `day` : `${recurrence.repeatInterval} days`}`,
      Weekly: `${recurrence.repeatInterval <= 1 ? `week` : `${recurrence.repeatInterval} weeks`}`,
      Monthly: `${recurrence.repeatInterval <= 1 ? `month` : `${recurrence.repeatInterval} months`}`,
      Yearly: 'year',
    };
    return frequency[recurrence.frequencyOption];
  }, [recurrence.frequencyOption, recurrence.repeatInterval]);

  const frequencyPreposition = useMemo(() => {
    switch (recurrence.frequencyOption) {
      case 'Monthly':
        return ' on the';
      case 'Yearly':
      case 'Weekly':
        return ' on';
    }
  }, [recurrence.frequencyOption]);

  const recurrenceIntervalDetails = useMemo(() => {
    switch (recurrence.frequencyOption) {
      case 'Weekly':
        return ` ${recurrence.dayInWeek}`;
      case 'Monthly':
        if (recurrence.monthlyOption === 'day') {
          return ` ${recurrence.dayInMonth === 'last' ? 'last day of the month' : `${recurrence.dayInMonth} day`}`;
        } else {
          return ` ${recurrence.monthlyOption} ${recurrence.dayInWeek}`;
        }
      case 'Yearly':
        return ` ${
          recurrence.dayInMonth === 'last'
            ? `the last day in ${recurrence.monthInYear}`
            : `${recurrence.monthInYear} ${recurrence.dayInMonth}`
        }`;
    }
  }, [recurrence]);

  const endPreposition = useMemo(() => {
    if (recurrence.endOption === 'Never') {
      return 'with';
    } else {
      return 'ending on';
    }
  }, [recurrence.endOption]);

  const recurringScheduleSentence = useMemo(() => {
    return invoiceType === invoiceTypes.oneTime ? null : (
      <label>
        This invoice will repeat <b>every {recurrenceInterval}</b> {frequencyPreposition}
        <b>{recurrenceIntervalDetails}</b> starting {`${initialInvoiceDate.isSame(moment(), 'day') ? 'today' : 'on'} `}{' '}
        <b>{initialInvoiceDate.format(defaultDateFormat)}</b>, {endPreposition}{' '}
        <b className={invalidEndDate ? 'cps-has-error' : ''}>
          {recurrence.endOption === 'Never' || !finalInvoiceDate
            ? 'no end date'
            : finalInvoiceDate.format(defaultDateFormat)}
        </b>
        , to be{' '}
        {recurrence.recurringTerms.id === 0 ? (
          <span>
            <b>due on receipt</b>.
          </span>
        ) : (
          <span>
            due{' '}
            <b>
              {recurrence.recurringTerms.id === -1 ? recurrence.fixedTerm : recurrence.recurringTerms.id}
              {' day(s) '}
            </b>
            from invoice date.
          </span>
        )}
      </label>
    );
  }, [invoiceType, recurrence, initialInvoiceDate, finalInvoiceDate]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'endPreposition', 'frequencyPreposition', 'invalidEndDate', 'recurrenceInterval', and 'recurrenceIntervalDetails'

  const lineItemGrandTotal = useMemo(() => {
    const timeEntryTotal = calculateSubTotal(invoice.lineItems.fromTime).sumTotal;
    const lineItemTotal = calculateSubTotal(invoice.lineItems.standard).sumTotal;
    const lateFeesTotal = calculateSubTotal(invoice.lineItems.lateFees).sumTotal;
    const creditsTotal = calculateCreditsTotal(availableCredits);

    return timeEntryTotal + lineItemTotal + lateFeesTotal - creditsTotal;
  }, [invoice.lineItems.fromTime, invoice.lineItems.standard, invoice.lineItems.lateFees, availableCredits]);

  const paymentsReceived = useMemo(() => {
    return invoice.payments ? paymentsCalc.getTotal(invoice.payments) : 0;
  }, [invoice]);

  const balanceDue = lineItemGrandTotal - paymentsReceived;

  const remainingText = useMemo(() => {
    const sent = recurrence.relationships?.invoices?.length;
    const max = sent + parseInt(recurrence.remaining);

    return recurrence.remaining > 0
      ? `${recurrence.endOption === 'Never' || max > 99 ? '99+' : recurrence.remaining + '/' + max} invoices remaining`
      : `${sent} invoices sent`;
  }, [recurrence]);

  return (
    <div className={`${styles.invoiceBuilderFooter} ${styles.shadowTop}`}>
      <div className="cp-pr-16">{recurringScheduleSentence}</div>
      <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
        {invoiceType === invoiceTypes.recurring && recurrence.id && (
          <>
            <div className="cp-mr-16" style={{ display: 'flex', alignItems: 'center' }}>
              {recurrence.status === 'Active' ? (
                <CpIcon name="checkmark-circle-open-small" fill="var(--cps-color-green)" size="32" />
              ) : (
                <CpIcon name="remove-circle-open-small" fill="var(--cps-color-monsoon)" size="32" />
              )}
              <div className="cp-pl-4 cp-color-app-secondary-text">
                <div className="cps-body cps-wt-semibold">
                  {recurrence.status === 'Active' ? 'Active recurrence' : recurrence.status}
                </div>
                <div style={{ fontStyle: 'italic' }}>{remainingText}</div>
              </div>
            </div>
            <div className="cp-mr-16" style={{ display: 'flex', alignItems: 'center' }}>
              {recurrence.payment.cardId && recurrence.status !== 'Ended' ? (
                <CpIcon name="checkmark-circle-open-small" fill="var(--cps-color-green)" size="32" />
              ) : (
                <CpIcon name="remove-circle-open-small" fill="var(--cps-color-monsoon)" size="32" />
              )}
              <div className="cp-pl-4 cp-color-app-secondary-text">
                <div className="cps-body cps-wt-semibold">
                  {recurrence.payment.cardId
                    ? recurrence.status === 'Ended'
                      ? 'Autopay ended'
                      : 'Autopay set up'
                    : 'No autopay set up'}
                </div>
                {recurrence.payment.cardId && (
                  <div style={{ fontStyle: 'italic' }}>{`Paid on ${
                    recurrence.payment.terms === 0 ? 'invoice date' : 'due date'
                  }`}</div>
                )}
              </div>
            </div>
          </>
        )}
        {paymentsReceived > 0 && (
          <>
            <div className={`cp-ph-16 ${balanceDue < 0 ? 'cps-color-error' : ''}`}>
              <CpTooltip
                text={balanceDue < 0 ? 'Invoice total cannot be less than payments received.' : 'Balance due'}
                position="top"
              >
                <div>
                  <div>Balance Due</div>
                  <div className={styles.grandTotalAmount}>{toCurrency(balanceDue)}</div>
                </div>
              </CpTooltip>
            </div>
            <div className="cp-ph-16">
              <div>Payments Received</div>
              <div className={styles.grandTotalAmount}>{toCurrency(paymentsReceived)}</div>
            </div>
          </>
        )}
        <div className="cp-ph-16" style={{ borderLeft: '1px solid var(--cps-color-border)' }}>
          <div>Grand Total</div>
          <div className={styles.grandTotalAmount}>{toCurrency(lineItemGrandTotal)}</div>
        </div>
      </div>
    </div>
  );
};
