import React from 'react';
import { DateTime } from 'luxon';
import { showInvoiceOverlay } from '../../../invoices/components/invoice-overlay/invoice-overlay-wrapper.component';
import { convertToCurrencyText } from 'src/payments/payments.helper';

import styles from './credit-history.styles.css';

export const CreditHistory = ({ credit, onClose }) => {
  const handleViewInvoice = overlayProps => {
    showInvoiceOverlay(overlayProps);
    onClose();
  };

  const handleViewPayment = paymentId => {
    window.location = `#/billing/payments/collected/${paymentId}/receipt`;
    onClose();
  };

  const messages = {
    created: 'Credit created',
    credit_invoice_added: 'A credit of {$value} was applied to Invoice {$invoice}',
    credit_invoice_removed: 'A credit of {$value} was deleted from Invoice {$invoice}',
    credit_invoice_changed: 'Credit changed to {$value}',
    credit_invoice_refunded: 'A credit of {$refundValue} was refunded',
    credit_invoice_returned: 'A credit of {$refundValue} was returned',
    credit_invoice_updated: 'A previously applied credit was edited to be {$value} on Invoice {$invoice}',
    full_credit_refund: 'A full refund of {$refundValue} was applied. {$payment}',
    partial_credit_refund: 'A partial refund of {$refundValue} was applied. {$payment}',
    payment_credit_added: 'Credit of {$value} was added from Payment {$payment}',
    payment_credit_deleted: 'Credit of {$value} was deleted',
    payment_credit_disputed: 'Payment was disputed by your customer',
    payment_credit_disputed_won: 'Dispute on payment was won',
    payment_credit_disputed_lost: 'Dispute on payment was lost',
  };

  const getHistoryMessage = ({ name, value }, showLink, onOpenItem) => {
    const parts = messages[name]?.split(/\{([^}]+)\}/g);
    return parts?.map(part => {
      if (part === '$value') {
        return convertToCurrencyText(value.amount);
      } else if (part === '$refundValue') {
        return convertToCurrencyText(value.refund_display_amount);
      } else if (part === '$payment' && showLink) {
        return (
          <a key={value.payment_number} onClick={onOpenItem}>
            #{value.payment_number}
          </a>
        );
      } else if (part === '$invoice' && showLink) {
        return (
          <a key={value.invoice_number} onClick={onOpenItem}>
            #{value.invoice_number}
          </a>
        );
      }

      return part;
    });
  };

  return (
    <div className={styles.creditHistory}>
      <div className="cps-body cps-wt-semibold cp-pb-12">Credit History</div>
      {credit?.history && (
        <ul>
          {credit.history
            .sort((a, b) => b.date - a.date)
            .filter(item => item.name !== 'credit_invoice_changed')
            .map((item, index) => {
              const showLink = !!item.value;
              const overlayProps = {
                overlayData: { invoiceId: item?.value?.invoice_id, mode: 'view', type: 'invoice' },
                onModalClose: onClose,
              };

              const openPayment =
                item.name === 'payment_credit_added' ||
                item.name === 'partial_credit_refund' ||
                item.name === 'full_credit_refund';

              const historyMessage = getHistoryMessage(
                item,
                showLink,
                openPayment ? () => handleViewPayment(item.value.payment_id) : () => handleViewInvoice(overlayProps)
              );

              return (
                historyMessage && (
                  <li key={index}>
                    <div style={{ width: '115px' }}>{DateTime.fromISO(item.date).toLocaleString()}</div>
                    <div>
                      {historyMessage}
                      {'  '}
                      <span className="cps-caption cp-color-app-secondary-text" style={{ fontStyle: 'italic' }}>
                        by {item.performed_by_name}
                      </span>
                    </div>
                  </li>
                )
              );
            })}
        </ul>
      )}
    </div>
  );
};
