import React, { useState, useEffect } from 'react';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { RoutingAccountImage } from '../on-boarding/routing-account-image.component';
import { wholeNumberFilter } from 'src/billing-helpers';
import { emptyFieldMessage, states } from '../on-boarding/on-boarding.helpers';
import { PractitionerAuthorization } from '../../../payment-settings/practitioner-authorization.component';

export const PaymentFormACH = ({
  achInfo,
  achInfoChanged,
  invalidFields,
  clearValidationError,
  validateACHData,
  isFuturePayment,
  disableSave,
}) => {
  const [user] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';

  const [routingNumActive, setRoutingNumActive] = useState(false);
  const [accountNumActive, setAccountNumActive] = useState(false);

  const [addressState, setAddressState] = useState(states.find(state => state.key === achInfo.state));

  useEffect(() => {
    if (isFuturePayment) {
      handleSaveAccountChanged({ target: { checked: true }, persist: () => {} });
    }
  }, [isFuturePayment]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'handleSaveAccountChanged'

  const handleAccountNameChanged = e => {
    e.persist();
    const accountName = e.target.value.trimLeft();
    const index = accountName.indexOf(' ');
    const firstName = index > 0 ? accountName.substr(0, index) : accountName;
    const lastName = index > 0 ? accountName.substr(index + 1).trim() : '';
    achInfoChanged({ ...achInfo, accountName, firstName, lastName });
  };

  const handleSaveAccountChanged = e => {
    e.persist();
    const saveAccount = e.target.checked;
    achInfoChanged({ ...achInfo, saveAccount });
  };

  return (
    <form name="form" className="cp-mb-24">
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div>
          <label className="cps-body-sm cp-mt-8">&nbsp;</label>
          <div style={{ display: 'flex', width: '180px' }}>
            <div>
              <label className="cps-radio">
                <input
                  type="radio"
                  name="accountType"
                  value={achInfo.accountType}
                  checked={achInfo.accountType === 'checking'}
                  onChange={() => achInfoChanged({ ...achInfo, accountType: 'checking' })}
                />
                <span className="cps-body-sm">Checking</span>
              </label>
            </div>
            <div className="cp-ml-12">
              <label className="cps-radio">
                <input
                  type="radio"
                  name="accountType"
                  value={achInfo.accountType}
                  checked={achInfo.accountType === 'savings'}
                  onChange={() => achInfoChanged({ ...achInfo, accountType: 'savings' })}
                />
                <span className="cps-body-sm">Savings</span>
              </label>
            </div>
          </div>
        </div>
        <div className={`cp-ml-24 ${invalidFields.includes('routingNumber') ? 'cps-has-error' : ''}`}>
          <label className="cps-body-sm cp-mt-8">
            Routing number <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="routingNumber"
              required
              className="cps-form-control"
              style={{ width: '180px' }}
              maxLength={'9'}
              type="text"
              value={achInfo.routingNumber}
              onFocus={e => {
                clearValidationError(e);
                setRoutingNumActive(true);
              }}
              onChange={e => achInfoChanged({ ...achInfo, routingNumber: wholeNumberFilter(e.target.value, true) })}
              onBlur={e => {
                validateACHData(e);
                setRoutingNumActive(false);
              }}
            />
            <span className="cps-error-block">Invalid routing number</span>
          </div>
        </div>
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('accountNumber') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            Account number <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="accountNumber"
              required
              className="cps-form-control"
              style={{ width: '180px' }}
              maxLength={'17'}
              type="text"
              value={achInfo.accountNumber}
              onFocus={e => {
                clearValidationError(e);
                setAccountNumActive(true);
              }}
              onChange={e => achInfoChanged({ ...achInfo, accountNumber: wholeNumberFilter(e.target.value, true) })}
              onBlur={e => {
                validateACHData(e);
                setAccountNumActive(false);
              }}
            />
            <span className="cps-error-block">
              {achInfo.accountNumber === '' ? emptyFieldMessage : 'Invalid account number'}
            </span>
          </div>
        </div>
        <div className={`cp-ml-24 ${invalidFields.includes('confirmAccountNumber') ? 'cps-has-error' : ''}`}>
          <label className="cps-body-sm cp-mt-8">
            Confirm account number <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="confirmAccountNumber"
              required
              className="cps-form-control"
              style={{ width: '180px' }}
              maxLength={'17'}
              type="text"
              value={achInfo.confirmAccountNumber}
              onFocus={e => {
                clearValidationError(e);
                setAccountNumActive(true);
              }}
              onChange={e =>
                achInfoChanged({ ...achInfo, confirmAccountNumber: wholeNumberFilter(e.target.value, true) })
              }
              onBlur={e => {
                validateACHData(e);
                setAccountNumActive(false);
              }}
            />
            <span className="cps-error-block">
              {achInfo.confirmAccountNumber === '' ? emptyFieldMessage : "Account numbers don't match"}
            </span>
          </div>
        </div>
        <RoutingAccountImage routingNumActive={routingNumActive} accountNumActive={accountNumActive} />
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('accountName') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            Name on account <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="accountName"
              required
              className="cps-form-control"
              style={{ width: '524px' }}
              maxLength={'22'}
              type="text"
              value={achInfo.accountName}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={handleAccountNameChanged}
              onBlur={e => {
                validateACHData(e);
              }}
            />
            <span className="cps-error-block">
              {achInfo.firstName === '' && achInfo.lastName === ''
                ? emptyFieldMessage
                : 'First and Last name are required'}
            </span>
          </div>
        </div>
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('street') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            Street <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="street"
              required
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'50'}
              type="text"
              value={achInfo.street}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => achInfoChanged({ ...achInfo, street: e.target.value.trimLeft() })}
              onBlur={e => {
                validateACHData(e);
              }}
            />
            <span className="cps-error-block">Street is required</span>
          </div>
        </div>
        <div className="cp-ml-24">
          <label className="cps-body-sm cp-mt-8">Apt./Suite</label>
          <div style={{ position: 'relative' }}>
            <input
              id="street2"
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'30'}
              type="text"
              value={achInfo.street2}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => achInfoChanged({ ...achInfo, street2: e.target.value })}
              onBlur={e => {
                validateACHData(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="cp-mt-24" style={{ display: 'flex' }}>
        <div className={invalidFields.includes('city') ? 'cps-has-error' : ''}>
          <label className="cps-body-sm cp-mt-8">
            City <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="city"
              required
              className="cps-form-control"
              style={{ width: '250px' }}
              maxLength={'50'}
              type="text"
              value={achInfo.city}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => achInfoChanged({ ...achInfo, city: e.target.value.trimLeft() })}
              onBlur={e => {
                validateACHData(e);
              }}
            />
            <span className="cps-error-block">City is required</span>
          </div>
        </div>
        <div className={`cp-ml-24 ${invalidFields.includes('state') ? 'cps-has-error' : ''}`}>
          <label className="cps-body-sm cp-mt-8">
            State <span className="cps-input-required" />
          </label>
          <CpSelectSingle
            data={states}
            transformData={item => ({
              id: item.key,
              name: item.value,
            })}
            value={addressState}
            onChange={value => {
              setAddressState(value);
              achInfoChanged({ ...achInfo, state: value.key });
            }}
            contentWidth="block"
            style={{ width: '140px' }}
            triggerIsBlock
          />
          <div style={{ position: 'relative' }}>
            <span className="cps-error-block">State is required</span>
          </div>
        </div>
        <div className={`cp-ml-24 ${invalidFields.includes('zip') ? 'cps-has-error' : ''}`}>
          <label className="cps-body-sm cp-mt-8">
            Zip <span className="cps-input-required" />
          </label>
          <div style={{ position: 'relative' }}>
            <input
              id="zip"
              required
              className="cps-form-control"
              style={{ width: '95' }}
              maxLength={'5'}
              type="text"
              value={achInfo.zip}
              onFocus={e => {
                clearValidationError(e);
              }}
              onChange={e => {
                achInfoChanged({ ...achInfo, zip: wholeNumberFilter(e.target.value, true) });
              }}
              onBlur={e => {
                validateACHData(e);
              }}
            />
            <span className="cps-error-block">Must be 5 digits</span>
          </div>
        </div>
      </div>
      {!disableSave && (
        <div style={{ display: 'flex', paddingTop: '36px' }}>
          <div>
            <label className="cps-checkbox">
              <input
                type="checkbox"
                disabled={isFuturePayment}
                checked={achInfo.saveAccount}
                onChange={handleSaveAccountChanged}
              />
              <span className="cps-color-monsoon cps-body-sm">
                Save {isClient ? 'bank account' : 'ACH'} information for future payments
              </span>
            </label>
          </div>
          {achInfo.saveAccount && (
            <div className="cp-ml-32">
              <label>
                Account nickname <i className="cps-color-monsoon cps-caption">(optional)</i>
              </label>
              <input
                id="ccNickname"
                className="cps-form-control"
                style={{ width: '250px' }}
                maxLength="30"
                type="text"
                placeholder="30 characters max."
                value={achInfo.nickname}
                onChange={e => {
                  achInfoChanged({ ...achInfo, nickname: e.target.value });
                }}
              />
            </div>
          )}
        </div>
      )}
      {achInfo.saveAccount && (
        <>
          <div style={{ marginTop: '-12px' }}>
            <label className="cps-checkbox">
              <input
                type="checkbox"
                checked={achInfo.isPreferred}
                onChange={e => achInfoChanged({ ...achInfo, isPreferred: e.target.checked })}
              />
              <span className="cps-color-monsoon cps-body-sm">Make default payment method</span>
            </label>
          </div>
          <div style={{ marginTop: '24px', marginLeft: '-24px' }}>
            <PractitionerAuthorization
              teamCanUse={achInfo.teamCanUse}
              setTeamCanUse={value => achInfoChanged({ ...achInfo, teamCanUse: value })}
              paymentType="bank account"
            />
          </div>
        </>
      )}
    </form>
  );
};
