import React, { useCallback, useEffect, useState, useRef } from 'react';
import { DateTime } from 'luxon';
import { debounce, isEmpty } from 'lodash';
import {
  CpButton,
  CpIcon,
  CpInput,
  CpLoader,
  CpModal,
  CprDatepicker,
  CpSelectSingle,
  CpToggle,
  CpTooltip,
} from 'canopy-styleguide!sofe';
import { infoToast, successToast } from 'toast-service!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { CurrencyInput } from '../payments/components/add-payment/currency-input.component';
import { createExpense, getExpense, getNextExpenseNumber, saveExpense } from '../resources/expense.resources';
import { getClient, getClients, getTeamMembersV2 } from '../resources/clients.resources';
import { initialExpenseObj } from './expense.helper';

const ExpenseModal = ({
  clientId,
  externalExpenseId,
  getExpenses,
  initialExpense = initialExpenseObj,
  onAfterClose,
  onClose,
  pageLimit,
  showModal,
}) => {
  const [expense, setExpense] = useState({
    ...initialExpense,
    ...(!initialExpense.client_active && { client_id: null, client_name: null }),
  });
  const [clients, setClients] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [saving, setSaving] = useState(false);
  const canEditExpenses = !expense?.invoice_id;
  const saveSubscription = useRef();
  const title = `${expense?.id || externalExpenseId ? 'Edit' : 'Add'} Expense`;

  useEffect(() => {
    if (!showModal) return;

    let expenseSubscription;
    let expenseNumberSubscription;
    let clientSubscription;

    if (externalExpenseId) {
      expenseSubscription = getExpense(externalExpenseId).subscribe(
        res => setExpense({ ...res, ...(!res.client_active && { client_id: null, client_name: null }) }),
        handleError
      );
    }

    if (clientId && !expense?.id) {
      clientSubscription = getClient(clientId).subscribe(client => {
        setExpense(prevState => ({ ...prevState, client_id: client.id, client_name: client.name }));
      }, handleError);
    }

    if (!expense?.id && !externalExpenseId) {
      expenseNumberSubscription = getNextExpenseNumber().subscribe(expense_number => {
        setExpense(prevState => ({ ...prevState, expense_number }));
      }, handleError);
    }

    const teamMemberSubscription = getTeamMembersV2().subscribe(setTeamMembers, handleError);

    return () => {
      expenseSubscription?.unsubscribe();
      expenseNumberSubscription?.unsubscribe();
      clientSubscription?.unsubscribe();
      teamMemberSubscription.unsubscribe();
    };
  }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'expense?.id', and 'externalExpenseId'

  useEffect(() => {
    if (!isSearching) return;
    if (!searchValue) {
      setClients([]);
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    const clientSubs = getClients(searchValue).subscribe(results => {
      setClients(results);
      setIsSearching(false);
    }, handleError);

    return () => {
      clientSubs.unsubscribe();
    };
  }, [isSearching]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'searchValue'

  useEffect(() => {
    return () => {
      saveSubscription.current?.unsubscribe();
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchForClient = useCallback(
    // lint-TODO: Hook received a function whose dependencies are unknown. Pass an inline function instead
    debounce(() => setIsSearching(true), 400),
    []
  );

  const searchOnChange = useCallback(search => {
    setSearchValue(search);
    debounceSearchForClient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'debounceSearchForClient'

  const handleExpenseChange = (value, field) => {
    if (field === 'date') {
      value = !!value ? DateTime.fromJSDate(new Date(value)).toISODate() : null;
    }
    setExpense(prevState => ({ ...prevState, [field]: value }));
  };

  const save = () => {
    setSaving(true);
    const apiCall = expense?.id ? saveExpense : createExpense;
    saveSubscription.current = apiCall(expense).subscribe(
      response => {
        successToast(`Expense successfully ${expense?.id ? 'updated' : 'created'}`);
        if (expense?.expense_number !== response.expense_number) {
          infoToast('Expense number was updated to the next usable number');
        }
        getExpenses?.({ ...(pageLimit?.id == 'all' && { newPageLimit: 50 }) });
        setSaving(false);
        onClose();
        window.dispatchEvent(new CustomEvent('billing-ui::expense-saved'));
      },
      e => {
        setSaving(false);
        handleError(e);
      }
    );
  };

  return (
    <CpModal
      show={showModal}
      onClose={onClose}
      onAfterClose={() => {
        onAfterClose();
        setExpense(initialExpense);
      }}
    >
      <CpModal.Header title={title} />
      <CpModal.Body className="cp-flex-column cp-p-24">
        {isEmpty(expense) ? (
          <CpLoader />
        ) : (
          <>
            <section className="cp-flex-spread" style={{ alignItems: 'flex-end' }}>
              <div>
                <label htmlFor="expenseDate">
                  Date
                  <span className="cps-input-required" />
                </label>
                <CprDatepicker
                  id="expenseDate"
                  events={{
                    datechange: date => {
                      handleExpenseChange(date.detail, 'date');
                    },
                  }}
                  date={expense?.date}
                  orientation="bottom right"
                  disabled={!canEditExpenses}
                />
              </div>

              <div>
                <label className="cp-mr-8" htmlFor="expenseNumber">
                  Expense number
                  <span className="cps-input-required" />
                </label>
                <CpTooltip
                  text={
                    <>
                      Expense numbers are <br /> automatically generated <br /> and non-editable.
                    </>
                  }
                >
                  <CpIcon name="information-circle-open-small" fill="var(--cp-color-app-icon)" />
                </CpTooltip>
                <CpInput disabled name="expenseNumber" placeholder="Expense number" value={expense?.expense_number} />
              </div>
            </section>

            <div className="cp-flex-column cp-mt-24">
              <label htmlFor="clientName">
                Client name
                <span className="cps-input-required" />
              </label>
              <CpSelectSingle
                contentWidth="block"
                data={clients}
                name="clientName"
                placeholder="Add client"
                onChange={val => {
                  handleExpenseChange(val.id, 'client_id');
                  handleExpenseChange(val.name, 'client_name');
                }}
                searchOnChange={searchOnChange}
                searchValue={searchValue}
                triggerIsBlock
                value={expense?.client_id ? { id: expense?.client_id, name: expense?.client_name } : null}
                searchFilter={CpSelectSingle.filterAlpha(searchValue)}
                disabled={!canEditExpenses}
              />
            </div>

            <div className="cp-mt-24">
              <label htmlFor="expenseNote">Note</label>
              <CpInput
                name="expenseNote"
                placeholder="Add note"
                onChange={val => handleExpenseChange(val, 'note')}
                value={expense?.note?.trimLeft()}
                disabled={!canEditExpenses}
              />
            </div>
            <div className="cp-flex-column cp-mt-24">
              <label htmlFor="teamMember">Team member</label>
              <CpSelectSingle
                clearable
                contentWidth="block"
                data={teamMembers}
                name="teamMember"
                placeholder="Add team member"
                onChange={val => {
                  handleExpenseChange(val?.id || null, 'team_member_id');
                  handleExpenseChange(val?.name || null, 'team_member_name');
                }}
                searchOnChange={searchOnChange}
                searchValue={searchValue}
                triggerIsBlock
                value={
                  expense?.team_member_id ? { id: expense?.team_member_id, name: expense?.team_member_name } : null
                }
                searchFilter={CpSelectSingle.filterAlpha(searchValue)}
                disabled={!canEditExpenses}
              />
            </div>

            <section className="cp-flex cp-mt-24">
              <div>
                <label>
                  Amount<span className="cps-color-primary">*</span>
                </label>
                <CurrencyInput
                  onChange={val => handleExpenseChange(val, 'amount')}
                  value={expense?.amount}
                  disabled={!canEditExpenses}
                />
              </div>
              <div className="cp-flex cp-ml-16 cp-mb-4" style={{ alignItems: 'flex-end' }}>
                <CpToggle
                  checked={expense?.billable}
                  onChange={val => handleExpenseChange(val, 'billable')}
                  disabled={!canEditExpenses}
                />
                <label className="cp-ml-8">Billable</label>
              </div>
            </section>
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          className="cp-mr-8"
          disabled={
            !expense?.date ||
            !expense?.client_id ||
            !expense?.expense_number ||
            !Number(expense?.amount) > 0 ||
            !canEditExpenses
          }
          onClick={save}
          showLoader={saving}
        >
          {expense?.id || externalExpenseId ? 'Update' : 'Save'}
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};

export default ExpenseModal;
