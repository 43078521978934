import React, { useState, useEffect, useCallback } from 'react';
import { find, debounce } from 'lodash';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getClientOwners } from 'src/resources/clients.resources';

import styles from '../due-date/due-date.styles.css';

export const ClientOwner = ({ updateOwners, OwnerKey, hideLabel, clientOwnerWidth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState('');
  const [trigger, setTrigger] = useState(true);

  useEffect(() => {
    if (options.length === 0 || selected?.id === OwnerKey) return;

    const selectedOwner = find(options, ['id', OwnerKey]);
    if (selectedOwner) {
      setSelected(selectedOwner);
    }
  }, [options, OwnerKey]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'selected?.id'

  useEffect(() => {
    if (!trigger) return;

    setIsLoading(true);
    const sub = getClientOwners(search).subscribe(response => {
      setOptions(response.map(o => ({ id: o.id, name: o.name })));
      setIsLoading(false);
      setTrigger(false);
    }, handleError);

    return () => sub.unsubscribe();
  }, [trigger]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'search'

  const searchOptions = () => setTrigger(true);
  const debounchedSearch = useCallback(debounce(searchOptions, 1000), []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies:

  return (
    <div className={styles.flexContainer}>
      {!hideLabel && (
        <div className={styles.labelContainer}>
          <div className={styles.label}>Owner</div>
        </div>
      )}
      <div style={{ width: clientOwnerWidth ? clientOwnerWidth : 340 }}>
        <CpSelectSingle
          clearable
          contentWidth="block"
          data={options}
          insertSearch
          loading={isLoading}
          onChange={value => {
            setSelected(value);
            updateOwners('OwnerKey', value?.id);
          }}
          placeholder={!hideLabel ? 'Search for owner' : 'Owner'}
          searchOnChange={value => {
            setSearch(value);
            debounchedSearch();
          }}
          searchValue={search}
          triggerIsBlock
          value={selected}
        />
      </div>
    </div>
  );
};
