import React, { useState, useEffect, useMemo } from 'react';
import { noop } from 'lodash';
import { CpModal, CpRadio, CpButton, CpCard, CpCheckbox, currency } from 'canopy-styleguide!sofe';
import { CurrencyInput } from '../../payments/components/add-payment/currency-input.component';
import { WipReportTargetedWuWd } from './wip-report-targeted-wuwd.component';
import { formatNumberToCurrencyText } from './wip-report.helper';

import styles from './wip-report-create-invoice-modal.styles.css';

export const WIPReportCreateInvoiceModal = ({
  show,
  onClose,
  selectedEntries,
  onInvoiceEntries,
  setShowCreateInvoiceModal,
}) => {
  const [billingRate, setBillingRate] = useState('employee');
  const [targeted, setTargeted] = useState(false);
  const [assigneeTotal, setAssigneeTotal] = useState();
  const [serviceTotal, setServiceTotal] = useState();
  const [billedAmount, setBilledAmount] = useState(assigneeTotal);
  const [timeEntries, setTimeEntries] = useState([]);

  const grandTotal = useMemo(
    () =>
      timeEntries
        ?.map(entry => Number(billingRate === 'employee' ? entry.assignee_amount : entry.service_item_amount))
        .reduce((a, b) => a + b, 0),
    [billingRate, timeEntries]
  );

  const billedTotal = useMemo(
    () => timeEntries?.map(entry => Number(entry.billedAmount)).reduce((a, b) => a + b, 0),
    [timeEntries]
  );

  useEffect(() => {
    if (!show) return;
    setBilledAmount(assigneeTotal);
    setAssigneeTotal(selectedEntries.map(entry => Number(entry.assignee_amount)).reduce((a, b) => a + b));
    setServiceTotal(selectedEntries.map(entry => Number(entry.service_item_amount)).reduce((a, b) => a + b));
    return () => {
      setBillingRate('employee');
      setTargeted(false);
    };
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'assigneeTotal' and 'selectedEntries'

  useEffect(() => {
    setBilledAmount(assigneeTotal);
  }, [assigneeTotal]);

  useEffect(() => {
    setTimeEntries(
      selectedEntries.map(entry => ({
        ...entry,
        billedAmount: billingRate === 'employee' ? entry.assignee_amount : entry.service_item_amount,
      }))
    );
  }, [billingRate, selectedEntries]);

  const handleContinueToInvoice = () => {
    setShowCreateInvoiceModal(false);
    onInvoiceEntries(timeEntries);
  };

  return (
    <CpModal show={show} onClose={onClose} width={520}>
      <CpModal.Header title="Review WIP Amounts" />
      <CpModal.Body>
        <div className={`${styles.wipWuWdModalHeader} cp-flex-center cp-mt-8`}>
          <span>Choose billing rate</span>
          <hr className={styles.wipWuWdModalHorizontalRule} />
        </div>
        <CpRadio onChange={noop} value={billingRate} name="billing-rate">
          <div className="cp-flex-spread cp-mt-8 cp-mb-24">
            <CpCard
              className={`${styles.wipWuWdRadioCard} cp-flex-center ${
                billingRate === 'employee' ? styles.wipWuWdRadioCardSelected : ''
              }`}
              onClick={e => {
                e.preventDefault();
                setBillingRate('employee');
                setBilledAmount(assigneeTotal);
              }}
            >
              <CpRadio.Item style={{ width: '100%' }} id="employee">
                <div className="cp-ml-16">
                  <div className={styles.wipWuWdGrayTextSmall}>Assignee Total</div>
                  <div className={styles.wipWuWdRadioAmount}>{currency(assigneeTotal)}</div>
                </div>
              </CpRadio.Item>
            </CpCard>
            <CpCard
              className={`${styles.wipWuWdRadioCard} cp-flex-center ${
                billingRate === 'service' ? styles.wipWuWdRadioCardSelected : ''
              }`}
              onClick={e => {
                e.preventDefault();
                setBillingRate('service');
                setBilledAmount(serviceTotal);
              }}
            >
              <CpRadio.Item style={{ width: '100%' }} id="service">
                <div className="cp-ml-16">
                  <div className={styles.wipWuWdGrayTextSmall}>Service Total</div>
                  <div className={styles.wipWuWdRadioAmount}>{currency(serviceTotal)}</div>
                </div>
              </CpRadio.Item>
            </CpCard>
          </div>
        </CpRadio>
        <div className={`${styles.wipWuWdModalHeader} cp-flex-center`}>
          <span>Edit total amount</span>
          <hr className={styles.wipWuWdModalHorizontalRule} />
        </div>
        <div className="cp-m-8 cp-mb-16" style={{ fontSize: '14px' }}>
          <div className="cp-flex-spread cp-mb-16">
            <span>Total amount selected</span>
            <span className="cp-mr-8">
              {currency(
                billingRate === 'employee' ? assigneeTotal : billingRate === 'service' ? serviceTotal : billedAmount
              )}
            </span>
          </div>
          <div className="cp-flex-spread cp-mb-8">
            <span>Write Up/Write Down</span>
            <span className="cp-mr-8">
              {targeted
                ? formatNumberToCurrencyText(billedTotal - grandTotal)
                : formatNumberToCurrencyText(
                    billedAmount - (billingRate === 'employee' ? assigneeTotal : serviceTotal)
                  )}
            </span>
          </div>
          <CpCheckbox className="cp-ml-8 cp-mb-16" onChange={setTargeted} checked={targeted}>
            <div style={{ fontSize: '12px' }}>Targeted write up/write down</div>
            <div className={styles.wipWuWdCheckboxSubHeader}>Write-up/down a specific time entry.</div>
          </CpCheckbox>
          {!targeted && (
            <>
              <span>Amount to be billed</span>
              <CurrencyInput value={billedAmount} onChange={setBilledAmount} alignRight />
            </>
          )}
        </div>
        {targeted && (
          <WipReportTargetedWuWd
            timeEntries={timeEntries}
            setTimeEntries={setTimeEntries}
            billingRate={billingRate}
            grandTotal={grandTotal}
            billedTotal={billedTotal}
          />
        )}
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={handleContinueToInvoice}>
          Continue to invoice
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
};
