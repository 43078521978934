import React, { useContext, useEffect, useMemo, useState } from 'react';
import { forkJoin, of } from 'rxjs';

import { handleError } from 'src/common/handle-error.helper';
import { Header } from './header.component';
import { LineItems } from './line-items.component';
import { InvoiceOptions } from './invoice-options.component';
import { Footer } from './footer.component';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { invoiceTypes } from 'src/invoices/invoices.helper';
import { QboSyncOptions } from './qbo-sync-options.component';
import { getClasses, getLocations } from 'src/resources/integrations.resources';
import { getClient } from 'src/resources/clients.resources';

import styles from './invoice-builder.styles.css';

export const InvoiceBuilder = ({ tenant, qboIntegration }) => {
  const { invoiceType, actions, invoice, recurrence } = useContext(InvoiceContext);
  const [qboClasses, setQboClasses] = useState();
  const [qboLocations, setQboLocations] = useState();
  const [clientSyncedToQbo, setClientSyncedToQbo] = useState(true);

  useEffect(() => {
    if (qboIntegration?.connected) {
      let classesSub = of([]);
      let locationsSub = of([]);

      if (
        qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxn ||
        qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine
      ) {
        classesSub = getClasses(qboIntegration.id, qboIntegration.type);
      }

      if (qboIntegration?.preferences?.AccountingInfoPrefs?.TrackDepartments) {
        locationsSub = getLocations(qboIntegration.id, qboIntegration.type);
      }

      const subscription = forkJoin([classesSub, locationsSub]).subscribe(([classes, locations]) => {
        setQboClasses(classes);
        setQboLocations(locations);
      }, handleError);

      return () => subscription.unsubscribe();
    }
  }, [qboIntegration?.connected, qboIntegration?.preferences?.AccountingInfoPrefs, invoice.id]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'qboIntegration.id' and 'qboIntegration.type'

  useEffect(() => {
    if (qboIntegration?.connected && invoice.client?.id) {
      const subscription = getClient(invoice.client.id).subscribe(client => {
        setClientSyncedToQbo(!!client.third_party_id);
      }, handleError);

      return () => subscription.unsubscribe();
    }
  }, [invoice.client?.id, qboIntegration?.connected]);

  const showQboSyncOptions = useMemo(
    () =>
      qboIntegration?.connected &&
      (qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxn ||
        qboLocations?.length ||
        qboIntegration?.disconnect_error ||
        !clientSyncedToQbo),
    [
      qboIntegration?.connected,
      qboLocations,
      qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxn,
      qboIntegration?.disconnect_error,
      clientSyncedToQbo,
    ]
  );

  const showClassCol = useMemo(
    () => qboIntegration?.connected && qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qboIntegration?.connected]
    // lint-TODO: has missing dependencies: 'qboIntegration?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine'
  );

  const scrollableYTopStyle = useMemo(() => {
    if (showQboSyncOptions && invoiceType === invoiceTypes.recurring) {
      return recurrence.showDateReset ? '356px' : '336px';
    } else if (showQboSyncOptions || invoiceType === invoiceTypes.recurring) {
      return recurrence.showDateReset ? '240px' : '220px';
    } else {
      return '117px';
    }
  }, [showQboSyncOptions, invoiceType, recurrence.showDateReset]);

  return (
    <>
      <Header tenant={tenant} />
      {showQboSyncOptions && (
        <div className="cp-mt-16">
          <QboSyncOptions
            qboIntegration={qboIntegration}
            qboClasses={qboClasses}
            qboLocations={qboLocations}
            update={
              invoiceType === invoiceTypes.recurring ? actions.updateRecurrenceFormData : actions.updateInvoiceFormData
            }
            invoice={invoiceType === invoiceTypes.recurring ? recurrence : invoice}
            clientSyncedToQbo={clientSyncedToQbo}
          />
        </div>
      )}
      <div className={styles.scrollableY} style={{ top: scrollableYTopStyle }}>
        <div className={styles.scrollableContainer}>
          <LineItems showClassCol={showClassCol} qboClasses={qboClasses} clientSyncedToQbo={clientSyncedToQbo} />
          <InvoiceOptions />
        </div>
      </div>
      <Footer />
    </>
  );
};
