import React, { useState, useEffect } from 'react';
import { CpSelectSingle } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getSubTasksOfTask } from 'src/resources/tasks.resources.js';

export default function TimeSubtaskSearch({ task, subtask, onSubtaskChanged, disabled, fixedContent }) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedSubtask, setSelectedSubtask] = useState(subtask);
  const [tasks, setTasks] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsSearching(true);
  }, [task]);

  useEffect(() => {
    setSelectedSubtask(subtask);
  }, [subtask]);

  const onSelectedSubtaskChanged = task => {
    setSelectedSubtask(task);
    onSubtaskChanged(task ? { id: task.id, name: task.name } : null);
  };

  useEffect(() => {
    if (!isSearching || !isOpen) return;
    const taskSubs = getSubTasksOfTask(task.id).subscribe(results => {
      setTasks(results.filter(task => task.status_id !== 'COMPLETED'));
      setIsSearching(false);
    }, handleError);

    return () => {
      taskSubs.unsubscribe();
    };
  }, [isSearching, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'task.id'

  return (
    <CpSelectSingle
      clearable
      contentWidth="block"
      data={tasks}
      disabled={!task?.id || disabled}
      fixedContent={fixedContent}
      loading={isSearching}
      onChange={onSelectedSubtaskChanged}
      onOpen={() => {
        setIsOpen(true);
        setIsSearching(true);
      }}
      onClose={() => setIsOpen(false)}
      placeholder="Search for a subtask"
      searchFilter
      searchValue={searchValue}
      searchOnChange={val => setSearchValue(val)}
      triggerIsBlock
      value={selectedSubtask}
    />
  );
}
