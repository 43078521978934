import React, { useState, useEffect } from 'react';
import { CpSelectMultiPills } from 'canopy-styleguide!sofe';
import styles from '../due-date/due-date.styles.css';

export const Clients = ({ ClientsKeys, clientsFromResponse, updateClientsKeys }) => {
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    applyPropsToState();
  }, [ClientsKeys]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'applyPropsToState'

  const applyPropsToState = () => {
    const itemsToSet = ClientsKeys.reduce((acc, item) => {
      const matchingClient = clientsFromResponse.filter(item2 => item2.id === item);
      if (matchingClient.length) {
        const name = matchingClient[0].name;
        acc.push({
          id: item,
          name: name,
        });
      }
      return acc;
    }, []);
    setSelected(itemsToSet);
  };

  return (
    <div className={`${styles.flexContainer}`}>
      <div className={`${styles.labelContainer}`}>
        <div className={`${styles.label}`}>Client</div>
      </div>
      <div style={{ width: '340px' }}>
        <CpSelectMultiPills
          data={clientsFromResponse}
          onChange={val => {
            setSelected(val);
            updateClientsKeys(
              'ClientsKeys',
              val.map(item => item.id)
            );
            setSearchValue('');
          }}
          placeholder="Search for clients"
          renderPill={item => item.name}
          searchFilter={CpSelectMultiPills.filterAlpha(searchValue)}
          searchOnChange={setSearchValue}
          searchValue={searchValue}
          value={selected}
          transformData={person => ({
            id: person.id || Math.ceil(Math.random() * 100000),
            name: person.name,
          })}
          triggerIsBlock
        />
      </div>
    </div>
  );
};
