import React, { useState, useEffect } from 'react';
import { CpSelectSingle, useDebounce } from 'canopy-styleguide!sofe';
import { getClients } from 'src/resources/clients.resources';
import { handleError } from 'src/common/handle-error.helper';

export const ClientName = ({ value, update, disabled }) => {
  const [searchTrigger, setSearchTrigger] = useState(false);
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (!searchTrigger) return;
    if (search.length === 0) {
      setSearchTrigger(false);
      return;
    }

    const clientSubscription = getClients(search).subscribe(clients => {
      setClients(clients);
      setSearchTrigger(false);
    }, handleError);

    return () => clientSubscription.unsubscribe();
  }, [searchTrigger]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'search'

  const searchClients = () => setSearchTrigger(true);

  const debouncedSearch = useDebounce(searchClients, 1000);

  return (
    <div>
      <div className="cp-label">
        Client Name<span className="cps-color-primary">*</span>
      </div>
      <CpSelectSingle
        triggerIsBlock
        contentWidth="md"
        fixedContent
        disabled={disabled}
        data={clients}
        value={value}
        placeholder="Search for client"
        onChange={client => update({ client: { id: client.id, name: client.name } })}
        searchOnChange={value => {
          setSearch(value);
          debouncedSearch();
        }}
        searchValue={search}
      />
    </div>
  );
};
