import React, { useState, useEffect, useMemo } from 'react';
import { fromEvent, merge } from 'rxjs';
import { map } from 'lodash';
import { CpButton, CpIcon, CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { getClientProfitabilityColumns } from './profitability-columns';
import { getClientProfitabilityReport } from './profitability.resource';
import { ClientProfitabilityTaskRow } from './client-profitability-task-row.component';

export const ProfitabilityMidRow = ({
  row,
  dateFilter,
  clientId,
  serviceItemId,
  groupedBy,
  sortColumn,
  isExpense,
  sortedByClientGroup,
}) => {
  const pageLimit = 50;
  const columns = useMemo(() => getClientProfitabilityColumns(), []);
  const [taskData, setTaskData] = useState();
  const [expanded, setExpanded] = useState(false);
  const [refetchTasks, setRefetchTasks] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const subs = merge(timeEntryEvents, invoiceEvents).subscribe(({ detail }) => {
      setRefetchTasks(
        groupedBy === 'client' ? detail.clientId == clientId : detail.serviceItemIds.includes(serviceItemId)
      );
    });
    return () => {
      subs.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'groupedBy', and 'serviceItemId'

  useEffect(() => {
    setExpanded(false);
    setTaskData();
  }, [dateFilter]);

  //for initial expansion
  useEffect(() => {
    if (expanded && !taskData) {
      const { after, before } = dateFilter;
      const subscription = getClientProfitabilityReport(
        {
          after,
          before,
          clientId: parseInt(clientId),
          serviceItemId: parseInt(serviceItemId),
          groupBy: 'task',
          sortColumn,
        },
        1,
        pageLimit
      ).subscribe(updateTable, handleError);

      return () => subscription.unsubscribe();
    }
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'dateFilter', 'serviceItemId', 'sortColumn', 'taskData', and 'updateTable'

  //for when the report gets updated from an external event such as billing of time entries with task from global +
  useEffect(() => {
    if (!refetchTasks) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientId: parseInt(clientId),
        serviceItemId: parseInt(serviceItemId),
        groupBy: 'task',
        sortColumn,
      },
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [refetchTasks]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'currentPage', 'dateFilter', 'serviceItemId', 'sortColumn', and 'updateTable'

  //for clicking load more button
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientId: parseInt(clientId),
        serviceItemId: parseInt(serviceItemId),
        groupBy: 'task',
        sortColumn,
      },
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'clientId', 'currentPage', 'dateFilter', 'serviceItemId', 'sortColumn', and 'updateTable'

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedTaskData = loadMore ? [...taskData, ...response.rows] : response.rows;
    setTaskData(updatedTaskData);
    setCurrentPage(refetchTasks ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setLoadMore(false);
    setRefetchTasks(false);
  };

  return (
    <>
      <tr key={row.row_id}>
        {map(columns, column =>
          column.key === 'row_name' ? (
            <td
              key={column.key}
              className="cp-ellipsis cps-wt-semibold"
              style={{
                cursor: isExpense ? 'default' : 'pointer',
                background: sortedByClientGroup ? 'white' : 'var(--cp-color-table-header-active-bg)',
                paddingLeft: isExpense
                  ? sortedByClientGroup
                    ? '72px'
                    : '56px'
                  : sortedByClientGroup
                    ? '48px'
                    : '32px',
              }}
              onClick={() => (isExpense ? {} : setExpanded(!expanded))}
            >
              {!isExpense && <CpIcon name={`caret-small-${expanded ? 'up' : 'down'}`} />}
              {row.row_name ? row.row_name : `-No ${groupedBy === 'client' ? 'Client' : 'Service Item'}-`}
            </td>
          ) : (
            <td
              key={column.key}
              className={`${column.key === 'user' ? '' : 'cp-text-right'} cps-wt-semibold`}
              style={{ background: sortedByClientGroup ? 'white' : 'var(--cp-color-table-header-active-bg)' }}
            >
              {column.renderCell(row[column.key])}
            </td>
          )
        )}
      </tr>
      {expanded &&
        (!taskData ? (
          <tr>
            <td colSpan={Object.keys(columns).length}>
              <CpLoader />
            </td>
          </tr>
        ) : (
          <>
            {taskData.map((task, index) => (
              <ClientProfitabilityTaskRow
                key={index}
                task={task}
                clientID={clientId}
                sortedByClientGroup={sortedByClientGroup}
              />
            ))}
            {hasMorePages && (
              <tr>
                <td colSpan={Object.keys(columns).length} className="cp-text-center cp-p-8">
                  <CpButton btnType="primary" onClick={() => setLoadMore(true)} showLoader={loadMore}>
                    Load more
                  </CpButton>
                </td>
              </tr>
            )}
          </>
        ))}
    </>
  );
};
