import React, { useState, useEffect, useMemo } from 'react';
import { fromEvent, merge } from 'rxjs';
import { map } from 'lodash';
import { CpButton, CpIcon, CpLoader } from 'canopy-styleguide!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { ProfitabilityMidRow } from './profitability-mid-row.component';
import { getClientProfitabilityColumns } from './profitability-columns';
import { getClientProfitabilityReport } from './profitability.resource';

export const ProfitabilityTopRow = ({
  row,
  dateFilter,
  groupedBy,
  groupId,
  ownerId,
  sortColumn,
  sortedByClientGroup,
}) => {
  const pageLimit = 50;
  const columns = useMemo(() => getClientProfitabilityColumns(), []);
  const [rowData, setRowData] = useState();
  const [expanded, setExpanded] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const isExpense = row.row_id === '0';

  useEffect(() => {
    const timeEntryEvents = fromEvent(window, 'billing-ui::time-saved');
    const invoiceEvents = fromEvent(window, 'billing-ui::event-saved');
    const subs = merge(timeEntryEvents, invoiceEvents).subscribe(({ detail }) => {
      setRefetchData(groupedBy === 'client' ? detail.clientId == groupId : detail.serviceItemIds.includes(groupId));
    });
    return () => {
      subs.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'groupId' and 'groupedBy'

  useEffect(() => {
    setExpanded(false);
    setRowData();
  }, [dateFilter]);

  //for initial expansion
  useEffect(() => {
    if (expanded && !rowData) {
      const { after, before } = dateFilter;
      const subscription = getClientProfitabilityReport(
        {
          after,
          before,
          clientId: groupedBy === 'client' ? groupId : undefined,
          serviceItemId: groupedBy === 'service_item' ? groupId : undefined,
          groupBy: groupedBy === 'client' ? 'service_item' : 'client',
          ownerId,
          sortColumn,
        },
        1,
        pageLimit
      ).subscribe(updateTable, handleError);

      return () => subscription.unsubscribe();
    }
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'dateFilter', 'groupId', 'groupedBy', 'ownerId', 'rowData', 'sortColumn', and 'updateTable'

  //for when the report gets updated from an external event such as billing of time entries from global +
  useEffect(() => {
    if (!refetchData) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientId: groupedBy === 'client' ? groupId : undefined,
        serviceItemId: groupedBy === 'service_item' ? groupId : undefined,
        groupBy: groupedBy === 'client' ? 'service_item' : 'client',
        ownerId,
        sortColumn,
      },
      1,
      pageLimit * currentPage
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [refetchData]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'currentPage', 'dateFilter', 'groupId', 'groupedBy', 'ownerId', 'sortColumn', and 'updateTable'

  //for clicking load more button
  useEffect(() => {
    if (!loadMore) return;
    const { after, before } = dateFilter;
    const subscription = getClientProfitabilityReport(
      {
        after,
        before,
        clientId: groupedBy === 'client' ? groupId : undefined,
        serviceItemId: groupedBy === 'service_item' ? groupId : undefined,
        groupBy: groupedBy === 'client' ? 'service_item' : 'client',
        ownerId,
        sortColumn,
      },
      currentPage + 1,
      pageLimit
    ).subscribe(updateTable, handleError);
    return () => subscription.unsubscribe();
  }, [loadMore]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'currentPage', 'dateFilter', 'groupId', 'groupedBy', 'ownerId', 'sortColumn', and 'updateTable'

  const updateTable = response => {
    const pageInfo = response.meta.paginator;
    const updatedRowData = loadMore ? [...rowData, ...response.rows] : response.rows;
    setRowData(updatedRowData);
    setCurrentPage(refetchData ? currentPage : pageInfo.current_page);
    setHasMorePages(!!pageInfo.next_page);
    setLoadMore(false);
    setRefetchData(false);
  };

  return (
    <>
      <tr key={row.row_id}>
        {map(columns, column =>
          column.key === 'row_name' ? (
            <td
              key={column.key}
              className="cp-ellipsis cps-wt-bold"
              style={{
                cursor: 'pointer',
                background: sortedByClientGroup ? 'var(--cp-color-table-header-active-bg)' : 'white',
                paddingLeft: sortedByClientGroup ? '32px' : '',
              }}
              onClick={() => setExpanded(!expanded)}
            >
              <CpIcon name={`caret-small-${expanded ? 'up' : 'down'}`} />
              {row.row_name ? row.row_name : `-No ${groupedBy === 'client' ? 'Client' : 'Service Item'}-`}
            </td>
          ) : (
            <td
              key={column.key}
              className={`${column.key === 'user' ? '' : 'cp-text-right'} cps-wt-bold`}
              style={{ background: sortedByClientGroup ? 'var(--cp-color-table-header-active-bg)' : 'white' }}
            >
              {column.renderCell(row[column.key])}
            </td>
          )
        )}
      </tr>
      {expanded &&
        (!rowData ? (
          <tr>
            <td colSpan={Object.keys(columns).length}>
              <CpLoader />
            </td>
          </tr>
        ) : (
          <>
            {rowData.map((row, index) => (
              <ProfitabilityMidRow
                key={index}
                row={row}
                dateFilter={dateFilter}
                clientId={groupedBy === 'client' ? groupId : row.row_id}
                serviceItemId={groupedBy === 'service_item' ? groupId : row.row_id}
                groupedBy={groupedBy === 'client' ? 'service_item' : 'client'}
                sortColumn={sortColumn}
                isExpense={row.row_id === '0' || isExpense}
                sortedByClientGroup={sortedByClientGroup}
              />
            ))}
            {hasMorePages && (
              <tr>
                <td colSpan={Object.keys(columns).length} className="cp-text-center cp-p-8">
                  <CpButton btnType="primary" onClick={() => setLoadMore(true)} showLoader={loadMore}>
                    Load more
                  </CpButton>
                </td>
              </tr>
            )}
          </>
        ))}
    </>
  );
};
