import React, { useEffect, useState } from 'react';
import { useInputWithDisplayValue } from 'src/common/custom-hooks';
import { decimalNumberFilter } from 'src/billing-helpers';
import { convertToCurrencyText } from 'src/payments/payments.helper';

export const CurrencyInput = ({
  value,
  onBlur,
  onChange,
  disabled = false,
  noPlaceholder = '',
  alignRight,
  allowComma = false,
  allowNegative = false,
}) => {
  const [highlightField, setHighlightField] = useState(null);
  const { boundValue, setBoundValue, displayValue, showInput, setShowInput, inputRef } = useInputWithDisplayValue(
    value,
    value => convertToCurrencyText(value, true, allowNegative)
  );

  useEffect(() => {
    setBoundValue(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'setBoundValue'

  useEffect(() => {
    if (!highlightField) return;

    highlightField.select();
    setHighlightField(null);
  }, [highlightField]);

  return (
    <>
      <input
        aria-label="bound-value"
        className="cps-form-control"
        ref={inputRef}
        type="text"
        maxLength="11"
        style={showInput ? (alignRight ? { textAlign: 'right' } : {}) : { display: 'none' }}
        value={boundValue}
        onFocus={e => {
          setHighlightField(e.target);
        }}
        onChange={e => {
          e.persist();
          let cursorPos = e.target.selectionStart;
          if (e.target.value === '.') {
            cursorPos += 1;
          }
          onChange(decimalNumberFilter(e.target.value, 2, allowComma, allowNegative), false);
          window.requestAnimationFrame(() => {
            if (e.target) {
              e.target.selectionStart = cursorPos;
              e.target.selectionEnd = cursorPos;
            }
          });
        }}
        onBlur={() => {
          let value = parseFloat(boundValue) || '';
          setShowInput(false);
          onBlur ? onBlur(value) : onChange(value, true, true);
        }}
      />
      <input
        aria-label="display-value"
        className={`cps-form-control ${boundValue ? '' : 'cps-color-af'}`}
        type="text"
        style={showInput ? { display: 'none' } : alignRight ? { textAlign: 'right' } : {}}
        defaultValue={noPlaceholder && !boundValue ? '' : displayValue}
        disabled={disabled}
        onFocus={() => setShowInput(true)}
      />
    </>
  );
};
