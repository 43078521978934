import React, { useEffect, useState, Suspense, lazy } from 'react';
import { get, pull } from 'lodash';
import { DateTime } from 'luxon';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { CpButton, CprDatepicker, CpSelectSingle, CpLoader } from 'canopy-styleguide!sofe';
import { luxonCreditCardFormat } from 'src/billing-helpers';
import { handleError } from 'src/common/handle-error.helper';
import {
  acceptedCardTypes,
  paymentMethods,
  paymentTypes,
  paysafeCardTypes,
  paymentViewMode,
} from 'src/payments/payments.helper';
import { getPaymentMethods } from 'src/resources/payment-settings.resources';
import { TotalPaymentCard } from './total-payment-card.component';
import { PaymentFormPaysafe } from './payment-form-paysafe.component';
import { PaymentFormACH } from './payment-form-ach.component';
import routingNumValidator from 'bank-routing-number-validator';

const AdyenPaymentForm = lazy(() =>
  import(/* webpackChunkName: "adyen" */ './adyen/payment-form.component').then(m => ({ default: m.AdyenPaymentForm }))
);

export const PaymentDetails = props => {
  const {
    adyenActions,
    adyenPaymentDetails,
    amexEnabled,
    hasAdyen,
    hasCanopyPayments,
    teamCanKeyInCards,
    isNewInvoiceExperience,
  } = props;
  const [user] = useWithUserAndTenant();
  const isClient = user?.role === 'Client';

  const ccDefault = {
    id: null,
    number: '',
    cursorPos: 0,
    type: 'Unknown',
    expMonth: '',
    expYear: '',
    cvv: '',
    name: '',
    zip: '',
    state: 'AZ',
    city: '',
    street: '',
    street2: '',
    saveCard: false,
    isExpired: false,
    isPreferred: false,
    teamCanUse: false,
    nickname: '',
    expirationDate: DateTime.local(),
    cardType: '',
    validCardType: true,
  };
  const achDefault = {
    accountType: 'checking',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    accountName: '',
    firstName: '',
    lastName: '',
    street: '',
    street2: '',
    city: '',
    state: 'AZ',
    zip: '',
    saveAccount: false,
    isPreferred: false,
    teamCanUse: false,
    nickname: '',
  };

  const [method, setMethod] = useState('');
  const [methodObject, setMethodObject] = useState();
  const [methodNickname, setMethodNickname] = useState('');
  const [refNumber, setRefNumber] = useState('');

  const [ccInfo, ccInfoChanged] = useState(ccDefault);

  const [achInfo, achInfoChanged] = useState(achDefault);

  const [invalidPaymentDateMessage, setInvalidPaymentDateMessage] = useState('');
  const [invalidFields, setInvalidFields] = useState([]);
  const [showNote, setShowNote] = useState(!!props.note);
  const [note, setNote] = useState(props.note || '');
  const [savedPaymentMethods, setSavedPaymentMethods] = useState({ cards: [], achs: [] });
  const [paymentMethodsInitialized, setPaymentMethodsInitialized] = useState(false);
  const [preferredMethod, setPreferredMethod] = useState('');
  const thisMonth = DateTime.local().startOf('month');
  const validCards = amexEnabled ? [...acceptedCardTypes, 'American Express'] : acceptedCardTypes;

  useEffect(() => {
    if (method || hasCanopyPayments === null || teamCanKeyInCards === null || hasAdyen === null) return;
    const defaultMethod = getDefaultPaymentMethod();
    if (defaultMethod) {
      setMethod(defaultMethod);
      setMethodObject(
        paymentMethods.map(m => ({ id: m.key, name: m.value, type: m.type })).find(m => m.id === defaultMethod)
      );
    }
  }, [hasCanopyPayments, hasAdyen, teamCanKeyInCards]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'getDefaultPaymentMethod' and 'method'

  useEffect(() => {
    const field = get(props.submitError, 'data.field_errors[0].field');
    if (field) {
      setInvalidFields([...invalidFields, field]);
    }
  }, [props.submitError]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'invalidFields'

  useEffect(() => {
    if (
      !method ||
      paymentMethodsInitialized ||
      hasCanopyPayments === null ||
      amexEnabled === null ||
      teamCanKeyInCards === null ||
      hasAdyen === null
    )
      return;

    setPaymentMethodsInitialized(true);

    if (!hasCanopyPayments || !props.clientId || (hasAdyen && !teamCanKeyInCards)) {
      resetToDefaultPaymentMethod();
      return;
    }

    if (props.edit && ['cash', 'check', 'other'].includes(method)) return;

    hasAdyen && adyenPaymentDetails.paymentMethod && adyenActions.setPaymentMethod({});

    getPaymentMethods(props.clientId).subscribe(response => {
      let cards = {};
      let achs = {};
      if (response && (response.cards || response.achs)) {
        if (response.cards.length === 0 && response.achs.length === 0) {
          resetToDefaultPaymentMethod();
          return;
        } else {
          const methodId = props.edit && props.method ? props.method.split(' ')[1] : '';
          let match = false;

          cards = response.cards.map(card => {
            // COMMENT: Removes unused property from card_expiry date object. Adyen does not have extra property fields. This code should be deleted with paysafe removal.
            if (!hasAdyen && card?.card_expiry) {
              card.card_expiry = { year: card?.card_expiry?.year, month: card?.card_expiry?.month };
            }
            const expirationDate = DateTime.fromObject(card.card_expiry);
            const isExpired = thisMonth.toISODate() > expirationDate.toISODate();
            const cardType = get(paysafeCardTypes, card.card_type);

            if (((!props.edit || !methodId) && card.is_preferred) || methodId === card.id) {
              setMethod(`cpStoredCreditCard ${card.id}`);
              setMethodObject({
                id: `cpStoredCreditCard ${card.id}`,
                name: getStoredPaymentNickname(card, 'cc'),
                icon: card.is_preferred ? 'shape-star-filled' : null,
              });
              setMethodNickname(getStoredPaymentNickname(card, 'cc'));
              ccInfoChanged({
                ...ccInfo,
                isExpired,
                expirationDate,
                isPreferred: card.is_preferred,
                teamCanUse: card.team_can_use,
                nickname: card.nickname,
                name: card.card_holder_name,
                zip: card.billing_address.zip,
                street: card.billing_address.street,
                street2: card.billing_address.street2,
                city: card.billing_address.city,
                state: card.billing_address.state,
                saveCard: isExpired,
                id: card.id,
                cardType: cardType,
                validCardType: hasAdyen || validCards.includes(cardType),
              });
              hasAdyen && teamCanKeyInCards && adyenActions.setPaymentMethod({ ...card, isExpired, expirationDate });
              match = true;
            }

            if (card.is_preferred) {
              setPreferredMethod(`cpStoredCreditCard ${card.id}`);
            }

            return {
              ...card,
              expirationDate,
              isExpired,
              isPreferred: card.is_preferred,
              cardType,
            };
          });

          achs = response.achs.map(account => {
            if (((!props.edit || !methodId) && account.is_preferred) || methodId === account.id) {
              setMethod(`cpStoredBankAccount ${account.id}`);
              setMethodObject({
                id: `cpStoredBankAccount ${account.id}`,
                name: getStoredPaymentNickname(account, 'ach'),
                icon: account.is_preferred ? 'shape-star-filled' : null,
              });
              setMethodNickname(getStoredPaymentNickname(account, 'ach'));
              achInfoChanged({ ...achInfo, isPreferred: account.is_preferred, teamCanUse: account.team_can_use });
              hasAdyen && teamCanKeyInCards && adyenActions.setPaymentMethod(account);
              match = true;
            }

            if (account.is_preferred) {
              setPreferredMethod(`cpStoredBankAccount ${account.id}`);
            }

            return { ...account, isPreferred: account.is_preferred, teamCanUse: account.team_can_use };
          });

          //PAYSAFE MIGRATION: Needed to check if saved method is a paysafe method on an adyen account.
          //If no matches found, defaults to credit card. Remove with removal of paysafe.
          if (props.edit && !match) {
            setMethod('cpCreditCard');
          }
        }
      } else {
        resetToDefaultPaymentMethod();
        return;
      }

      setSavedPaymentMethods({ cards, achs });
    }, handleError);
  }, [amexEnabled, hasAdyen, hasCanopyPayments, method, props.clientId, teamCanKeyInCards]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'achInfo', 'adyenActions', 'adyenPaymentDetails.paymentMethod', 'ccInfo', 'getStoredPaymentNickname', 'paymentMethodsInitialized', 'props.edit', 'props.method', 'resetToDefaultPaymentMethod', 'thisMonth', and 'validCards'

  useEffect(() => {
    props.detailsChanged?.({ ccInfo, achInfo, method, methodNickname, refNumber });
    let numberEl = document.getElementById('number');
    if (numberEl && document.activeElement === numberEl) {
      numberEl.selectionEnd = ccInfo.cursorPos;
    }
  }, [ccInfo, achInfo, method, refNumber]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'methodNickname' and 'props'

  useEffect(() => {
    props.noteChanged && props.noteChanged(note);
  }, [note]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'props'

  useEffect(() => {
    if (note !== props.note) {
      setNote(props.note);
      setShowNote(!!props.note);
    }
  }, [props.note]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'note'

  useEffect(() => {
    if (refNumber !== props.refNumber) {
      setRefNumber(props.refNumber);
    }
  }, [props.refNumber]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'refNumber'

  useEffect(() => {
    if (props.ccInfo) {
      ccInfoChanged(props.ccInfo);
    }
    if (props.achInfo) {
      achInfoChanged(props.achInfo);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'props.achInfo' and 'props.ccInfo'

  useEffect(() => {
    if (!hasAdyen || !teamCanKeyInCards) return;

    if (
      adyenPaymentDetails.paymentMethod.isExpired ||
      props.isFuturePayment ||
      props.paymentType === paymentTypes.recurring
    ) {
      adyenActions.setSavedMethod(true);
      return;
    }

    adyenActions.setSavedMethod(false);
  }, [adyenPaymentDetails?.paymentMethod]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'adyenActions', 'hasAdyen', 'props.isFuturePayment', 'props.paymentType', and 'teamCanKeyInCards'

  useEffect(() => {
    if (hasAdyen) return;
    validateCCData();
  }, [ccInfo]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'hasAdyen' and 'validateCCData'

  useEffect(() => {
    if (hasAdyen) return;
    validateACHData();
  }, [achInfo]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'hasAdyen' and 'validateACHData'

  useEffect(() => {
    validatePaymentDate(props.date);
  }, [method]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'props.date' and 'validatePaymentDate'

  const getDefaultPaymentMethod = () => {
    if (props.method) return props.method;

    if (hasCanopyPayments === null) return '';

    if ((hasCanopyPayments && hasAdyen && teamCanKeyInCards) || (!hasAdyen && hasCanopyPayments)) {
      return 'cpCreditCard';
    } else {
      return 'check';
    }
  };

  const resetToDefaultPaymentMethod = () => {
    setSavedPaymentMethods({ cards: [], achs: [] });
    const defaultMethod = getDefaultPaymentMethod();
    if (defaultMethod) {
      setMethod(defaultMethod);
      setMethodObject(
        paymentMethods.map(m => ({ id: m.key, name: m.value, type: m.type })).find(m => m.id === defaultMethod)
      );
    }
  };

  const validatePaymentDate = date => {
    const jsDate = date ? new Date(date) : null;
    const luxonDate = jsDate ? DateTime.fromJSDate(jsDate) : null;
    if (!date) {
      setInvalidPaymentDateMessage('Payment date is required');
      props.handlePaymentDateChanged?.(null);
    } else if (
      luxonDate?.startOf('day').diff(DateTime.local().startOf('day'), 'days').toObject().days > 0 &&
      ['cash', 'check', 'other'].includes(method)
    ) {
      setInvalidPaymentDateMessage('Future payments are not supported');
      props.handlePaymentDateChanged?.(DateTime.local().startOf('day'));
    } else if (
      luxonDate?.startOf('day').diff(DateTime.local().startOf('day'), 'days').toObject().days < 0 &&
      !['cash', 'check', 'other'].includes(method)
    ) {
      setInvalidPaymentDateMessage('Past payments are not supported');
      props.handlePaymentDateChanged?.(DateTime.local().startOf('day'));
    } else {
      setInvalidPaymentDateMessage('');
      if (props.date !== date) {
        props.handlePaymentDateChanged?.(luxonDate?.startOf('day').toISO());
      }
    }
  };

  const validateCCData = e => {
    if (!method || (method !== 'cpCreditCard' && !method.includes('cpStoredCreditCard'))) return true;

    let invalid = [...invalidFields];
    if (e) {
      pull(invalid, e.target.id);
    } else {
      e = { target: {} };
    }

    validateField('name', ccInfo.name, e.target.id, ccInfo.name.length, invalid);
    validateField('zip', ccInfo.zip, e.target.id, ccInfo.zip?.length === 5, invalid);

    let focused = document.activeElement;
    if (focused) {
      pull(invalid, focused.id);
    }
    setInvalidFields(invalid);
  };

  const validateACHData = e => {
    if (method !== 'cpAch') return true;

    let invalid = [...invalidFields];
    if (e) {
      pull(invalid, e.target.id);
    } else {
      e = { target: {} };
    }

    validateField(
      'routingNumber',
      achInfo.routingNumber,
      e.target.id,
      achInfo.routingNumber.length === 9 && routingNumValidator.ABARoutingNumberIsValid(achInfo.routingNumber),
      invalid
    );
    validateField('accountNumber', achInfo.accountNumber, e.target.id, achInfo.accountNumber.length >= 4, invalid);
    validateField(
      'confirmAccountNumber',
      achInfo.confirmAccountNumber,
      e.target.id,
      achInfo.confirmAccountNumber.length,
      invalid
    );
    if (achInfo.accountNumber.length && achInfo.confirmAccountNumber.length) {
      if (achInfo.accountNumber !== achInfo.confirmAccountNumber) {
        invalid.push('confirmAccountNumber');
      } else {
        pull(invalid, 'confirmAccountNumber');
      }
    }
    validateField('accountName', achInfo.firstName, e.target.id, achInfo.firstName.length, invalid);
    validateField('accountName', achInfo.lastName, e.target.id, achInfo.lastName.length, invalid);
    validateField('street', achInfo.street, e.target.id, achInfo.street.length, invalid);
    validateField('city', achInfo.city, e.target.id, achInfo.city.length, invalid);
    validateField('state', achInfo.state, e.target.id, achInfo.state.length === 2, invalid);
    validateField('zip', achInfo.zip, e.target.id, achInfo.zip.length === 5, invalid);

    let focused = document.activeElement;
    if (focused) {
      pull(invalid, focused.id);
    }
    setInvalidFields(invalid);
  };

  const validateField = (name, value, targetId, valid, invalidFields) => {
    if ((value || targetId === name) && !valid && !invalidFields.includes(name)) {
      invalidFields.push(name);
    }
  };

  const clearValidationError = e => {
    setInvalidFields(pull([...invalidFields], e.target.id));
  };

  const getPaymentOptions = () => {
    if (
      hasAdyen
        ? teamCanKeyInCards &&
          hasCanopyPayments &&
          (isClient || props.isFuturePayment || props.paymentType === paymentTypes.recurring)
        : hasCanopyPayments && (isClient || props.isFuturePayment || props.paymentType === paymentTypes.recurring)
    )
      return paymentMethods.filter(({ type }) => type.includes('canopy'));
    if (
      hasAdyen
        ? teamCanKeyInCards && hasCanopyPayments && (!props.edit || !['cash', 'check', 'other'].includes(props.method))
        : hasCanopyPayments && (!props.edit || !['cash', 'check', 'other'].includes(props.method))
    )
      return paymentMethods.filter(
        ({ type }) => type.includes('canopy') || (type.includes('manual') && !type.includes('non-canopy'))
      );
    return paymentMethods.filter(({ type }) => type.includes('manual'));
  };

  const getStoredPaymentNickname = (method, type) => {
    if (type === 'cc') {
      const cardType = get(paysafeCardTypes, method.card_type);
      return `${method.nickname || cardType} *${method.last_four}`;
    } else if (type === 'ach') {
      const accountType = method.account_type === 'CHECKING' ? 'Checking' : 'Savings';
      return `${method.nickname || accountType} *${hasAdyen ? method.last_four : method.last_two}`;
    }
  };

  const mergePaymentOptions = () => {
    const standardPaymentOptions = getPaymentOptions().map(spo => {
      return { name: isClient && spo.value === 'ACH' ? 'Bank Account' : spo.value, id: spo.key, type: spo.type };
    });

    let paymentOptions = [];

    if (!props.edit || !['cash', 'check', 'other'].includes(props.method)) {
      if (savedPaymentMethods) {
        if (savedPaymentMethods.cards.length > 0) {
          savedPaymentMethods.cards.forEach(card => {
            paymentOptions.push({
              id: `cpStoredCreditCard ${card.id}`,
              name: getStoredPaymentNickname(card, 'cc'),
              icon: card.is_preferred ? 'shape-star-filled' : null,
            });
          });
        }
        if (savedPaymentMethods.achs.length > 0) {
          savedPaymentMethods.achs.forEach(account => {
            paymentOptions.push({
              id: `cpStoredBankAccount ${account.id}`,
              name: getStoredPaymentNickname(account, 'ach'),
              icon: account.is_preferred ? 'shape-star-filled' : null,
            });
          });
        }
      }
    }

    const options = [
      ...(paymentOptions.length > 0
        ? [
            {
              id: 1,
              name: 'saved methods',
              data: paymentOptions,
            },
          ]
        : []),
      {
        id: 2,
        name: 'defaults',
        data: standardPaymentOptions,
      },
    ];

    return options;
  };

  const handleKeyChanged = value => {
    let paymentNickname = '';

    adyenActions.setPaymentMethod({});

    props.clearNotes?.();

    if (value.id.includes('cpStoredCreditCard')) {
      const id = value.id.split(' ')[1];
      const card = savedPaymentMethods.cards.find(card => card.id === id);
      const cardType = get(paysafeCardTypes, card.card_type);
      if (hasAdyen) {
        adyenActions.setPaymentMethod(card);
      } else {
        paymentNickname = getStoredPaymentNickname(card, 'cc');
        ccInfoChanged({
          ...ccInfo,
          isExpired: card.isExpired,
          isPreferred: card.isPreferred,
          teamCanUse: card.teamCanUse,
          expirationDate: card.expirationDate,
          saveCard: true,
          nickname: card.nickname,
          name: card.card_holder_name,
          zip: card.billing_address.zip,
          street: card.billing_address.street,
          street2: card.billing_address.street2,
          city: card.billing_address.city,
          state: card.billing_address.state,
          id: card.id,
          cardType: cardType,
          validCardType: hasAdyen || validCards.includes(card.cardType),
        });
      }
    } else if (value.id === 'cpCreditCard') {
      ccInfoChanged({ ...ccDefault, saveCard: props.isFuturePayment || props.paymentType === paymentTypes.recurring });
      adyenActions.setPaymentMethod({});
    } else if (value.id.includes('cpStoredBankAccount')) {
      const id = value.id.split(' ')[1];
      const ach = savedPaymentMethods.achs.find(ach => ach.id === id);
      if (hasAdyen) {
        adyenActions.setPaymentMethod(ach);
      } else {
        paymentNickname = getStoredPaymentNickname(ach, 'ach');
        achInfoChanged({ ...achInfo, isPreferred: ach.isPreferred });
      }
    } else if (value.id === 'cpAch') {
      achInfoChanged({ ...achDefault });
      adyenActions.setPaymentMethod({});
    } else {
      adyenActions.setPaymentMethod({});
    }
    setMethodNickname(paymentNickname);
    setMethod(value.id);
    setMethodObject(value);
  };

  return (
    <div style={props.show ? {} : { display: 'none' }}>
      {props.paymentType === paymentTypes.oneTime && (
        <TotalPaymentCard paymentTotal={props.paymentTotal} remainingTotal={props.remainingTotal} />
      )}
      <div
        className={`${isNewInvoiceExperience ? '' : 'cps-well__light cps-card__body'} cp-mb-24 ${
          props.paymentType === paymentTypes.oneTime ? 'cp-mt-24' : ''
        }`}
      >
        {(!isClient || hasCanopyPayments) && (
          <div style={{ display: 'flex' }}>
            {props.paymentType === paymentTypes.oneTime && (
              <div>
                <label>
                  Payment date
                  <span className="cps-input-required" />
                </label>
                <div className="cp-mt-4">
                  <CprDatepicker
                    name="paymentDate"
                    date={props.date}
                    events={{
                      datechange: date => {
                        validatePaymentDate(date.detail);
                      },
                    }}
                    orientation="bottom right"
                  />
                  {invalidPaymentDateMessage && <span className="cps-has-error">{invalidPaymentDateMessage}</span>}
                </div>
              </div>
            )}
            <div className={props.paymentType === paymentTypes.oneTime ? 'cp-ml-24' : ''}>
              <label>Payment method</label>
              <div className="cp-mt-4">
                <CpSelectSingle
                  disabled={props.disabled}
                  data={mergePaymentOptions()}
                  transformData={item => ({
                    ...item,
                    ...(item.icon && { iconColor: '#f1d500' }),
                  })}
                  value={methodObject}
                  onChange={value => {
                    handleKeyChanged(value);
                  }}
                  isGroupData={true}
                  groupType="border"
                  triggerIsBlock
                  style={{ minWidth: '324px' }}
                  contentWidth="block"
                />
              </div>
              {!hasAdyen && method?.includes('cpStoredCreditCard') && ccInfo.isExpired && (
                <span className="cps-color-warning cps-wt-semibold cps-caption cp-mt-4">
                  {`Expired ${ccInfo.expirationDate.toFormat(luxonCreditCardFormat)}`}
                </span>
              )}
              {hasAdyen &&
                teamCanKeyInCards &&
                adyenPaymentDetails.paymentMethod &&
                adyenPaymentDetails.paymentMethod.isExpired && (
                  <span className="cps-color-warning cps-wt-semibold cps-caption cp-mt-4">
                    {`Expired ${adyenPaymentDetails.paymentMethod.expirationDate.toFormat(luxonCreditCardFormat)}`}
                  </span>
                )}
            </div>
            {['cash', 'check', 'other'].includes(method) && (
              <div className="cp-ml-24">
                <label>{method === 'check' ? 'Check' : 'Reference'} number</label>
                <div>
                  <input
                    className="cps-form-control"
                    style={{ width: '134px' }}
                    maxLength="21"
                    type="text"
                    value={refNumber}
                    onChange={e => {
                      setRefNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {!hasAdyen && method?.includes('cpStoredCreditCard') && (
          <>
            {ccInfo.isExpired && (
              <PaymentFormPaysafe
                ccInfo={ccInfo}
                ccInfoChanged={ccInfoChanged}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
                clearValidationError={clearValidationError}
                validateCCData={validateCCData}
                onPaysafeInstance={props.onPaysafeInstance}
                onPaysafeUpdate={props.onPaysafeUpdate}
                validCards={validCards}
                isFuturePayment={props.isFuturePayment}
              />
            )}
            {!hasAdyen && !ccInfo.validCardType && (
              <div className="cps-caption cps-color-warning cp-mt-8">Card type is not supported</div>
            )}
          </>
        )}
        {method?.includes('cpStoredCreditCard') &&
          method !== preferredMethod &&
          ((!hasAdyen && ccInfo.validCardType) || (hasAdyen && !adyenPaymentDetails?.paymentMethod.isExpired)) && (
            <div className="cp-mt-24 cp-mb-24">
              <label className="cps-checkbox">
                <input
                  disabled={props.disabled}
                  type="checkbox"
                  checked={hasAdyen ? adyenPaymentDetails?.paymentInfo?.isPreferred : ccInfo.isPreferred}
                  onChange={e => {
                    if (hasAdyen) {
                      adyenActions.setPaymentInfo({
                        ...adyenPaymentDetails.paymentInfo,
                        isPreferred: e.target.checked,
                      });
                      adyenActions.setSavedMethod(true);
                      return;
                    }

                    ccInfoChanged({ ...ccInfo, isPreferred: e.target.checked, saveCard: e.target.checked });
                  }}
                />
                <span className="cps-color-monsoon cps-body-sm">Make default payment method</span>
              </label>
            </div>
          )}
        {hasAdyen && teamCanKeyInCards && method?.includes('cpStoredCreditCard') && (
          <>
            {adyenPaymentDetails.paymentMethod?.isExpired && (
              <Suspense fallback={<CpLoader />}>
                <AdyenPaymentForm
                  paymentType="card"
                  paymentMethod={adyenPaymentDetails.paymentMethod}
                  adyenSessionDetails={adyenPaymentDetails.adyenSessionDetails}
                  setAdyenInstance={adyenActions.setAdyenInstance}
                  isFuturePayment={props.isFuturePayment || props.paymentType === paymentTypes.recurring}
                  savedMethod={adyenPaymentDetails.savedMethod}
                  setSavedMethod={adyenActions.setSavedMethod}
                  savedMethodName={adyenPaymentDetails.paymentInfo.nickname}
                  setSavedMethodName={e =>
                    adyenActions.setPaymentInfo({ ...adyenPaymentDetails.paymentInfo, nickname: e })
                  }
                  defaultMethod={adyenPaymentDetails.paymentInfo.isPreferred}
                  setDefaultMethod={e =>
                    adyenActions.setPaymentInfo({ ...adyenPaymentDetails.paymentInfo, isPreferred: e })
                  }
                  paymentViewMode={paymentViewMode.Payments}
                  isInternal={user?.is_internal}
                />
              </Suspense>
            )}
          </>
        )}

        {method?.includes('cpStoredBankAccount') && method !== preferredMethod && (
          <div className="cp-mt-24 cp-mb-24">
            <label className="cps-checkbox">
              <input
                disabled={props.disabled}
                type="checkbox"
                checked={hasAdyen ? adyenPaymentDetails?.paymentInfo?.isPreferred : achInfo.isPreferred}
                onChange={e => {
                  if (hasAdyen) {
                    adyenActions.setPaymentInfo({ ...adyenPaymentDetails.paymentInfo, isPreferred: e.target.checked });
                    adyenActions.setSavedMethod(true);
                    return;
                  }

                  achInfoChanged({ ...achInfo, isPreferred: e.target.checked, saveAccount: e.target.checked });
                }}
              />
              <span className="cps-color-monsoon cps-body-sm">Make default payment method</span>
            </label>
          </div>
        )}
        {hasAdyen && teamCanKeyInCards && (method === 'cpCreditCard' || method === 'cpAch') && props.show && (
          <Suspense fallback={<CpLoader />}>
            <AdyenPaymentForm
              paymentType={method}
              adyenSessionDetails={adyenPaymentDetails.adyenSessionDetails}
              setAdyenInstance={adyenActions.setAdyenInstance}
              isFuturePayment={props.isFuturePayment || props.paymentType === paymentTypes.recurring}
              savedMethod={adyenPaymentDetails.savedMethod}
              setSavedMethod={adyenActions.setSavedMethod}
              savedMethodName={adyenPaymentDetails.paymentInfo.nickname}
              setSavedMethodName={e => adyenActions.setPaymentInfo({ ...adyenPaymentDetails.paymentInfo, nickname: e })}
              defaultMethod={adyenPaymentDetails.paymentInfo.isPreferred}
              setDefaultMethod={e =>
                adyenActions.setPaymentInfo({ ...adyenPaymentDetails.paymentInfo, isPreferred: e })
              }
              paymentViewMode={paymentViewMode.Payments}
              isInternal={user?.is_internal}
            />
          </Suspense>
        )}
        {!hasAdyen && method === 'cpCreditCard' && (
          <PaymentFormPaysafe
            ccInfo={ccInfo}
            ccInfoChanged={ccInfoChanged}
            invalidFields={invalidFields}
            setInvalidFields={setInvalidFields}
            clearValidationError={clearValidationError}
            validateCCData={validateCCData}
            onPaysafeInstance={props.onPaysafeInstance}
            onPaysafeUpdate={props.onPaysafeUpdate}
            validCards={validCards}
            isFuturePayment={props.isFuturePayment || props.paymentType === paymentTypes.recurring}
          />
        )}
        {!hasAdyen && method === 'cpAch' && (
          <PaymentFormACH
            achInfo={achInfo}
            achInfoChanged={achInfoChanged}
            invalidFields={invalidFields}
            clearValidationError={clearValidationError}
            validateACHData={validateACHData}
            isFuturePayment={props.isFuturePayment || props.paymentType === paymentTypes.recurring}
          />
        )}
        {!isClient && props.paymentType === paymentTypes.oneTime && (
          <div className="cp-mt-24">
            {!showNote && (
              <CpButton
                btnType="flat"
                onClick={() => {
                  setShowNote(true);
                }}
              >
                + Add a note
              </CpButton>
            )}
            {showNote && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label>Note</label>
                  <CpButton
                    icon="crud-trash-small"
                    onClick={() => {
                      setShowNote(false);
                      setNote('');
                    }}
                  />
                </div>
                <textarea
                  value={note}
                  style={{ minHeight: '56px' }}
                  onChange={e => {
                    e.persist();
                    setNote(e.target.value);
                  }}
                  className="cps-form-control +no-resize"
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
