import moment from 'moment';
import { get, find } from 'lodash';
import { invoiceCalc, invoiceItems, invoiceTerms } from './invoices.helper.js';
import { getRecurrence } from 'src/resources/invoices.resources.js';
import { ISODateFormat } from 'src/billing-helpers.js';
import { handleError } from 'src/common/handle-error.helper.js';

export const endRecurrenceOptions = [
  { value: 'After', key: 'After' },
  { value: 'On date', key: 'On date' },
  { value: 'Never', key: 'Never' },
];

export const frequencyOptions = [
  { value: 'Daily', key: 'Daily' },
  { value: 'Weekly', key: 'Weekly' },
  { value: 'Monthly', key: 'Monthly' },
  { value: 'Yearly', key: 'Yearly' },
];
export const monthsInYear = [
  { value: 'January', key: 'January' },
  { value: 'February', key: 'February' },
  { value: 'March', key: 'March' },
  { value: 'April', key: 'April' },
  { value: 'May', key: 'May' },
  { value: 'June', key: 'June' },
  { value: 'July', key: 'July' },
  { value: 'August', key: 'August' },
  { value: 'September', key: 'September' },
  { value: 'October', key: 'October' },
  { value: 'November', key: 'November' },
  { value: 'December', key: 'December' },
];
export const daysInMonth = [
  { value: '1st', key: '1st' },
  { value: '2nd', key: '2nd' },
  { value: '3rd', key: '3rd' },
  { value: '4th', key: '4th' },
  { value: '5th', key: '5th' },
  { value: '6th', key: '6th' },
  { value: '7th', key: '7th' },
  { value: '8th', key: '8th' },
  { value: '9th', key: '9th' },
  { value: '10th', key: '10th' },
  { value: '11th', key: '11th' },
  { value: '12th', key: '12th' },
  { value: '13th', key: '13th' },
  { value: '14th', key: '14th' },
  { value: '15th', key: '15th' },
  { value: '16th', key: '16th' },
  { value: '17th', key: '17th' },
  { value: '18th', key: '18th' },
  { value: '19th', key: '19th' },
  { value: '20th', key: '20th' },
  { value: '21st', key: '21st' },
  { value: '22nd', key: '22nd' },
  { value: '23rd', key: '23rd' },
  { value: '24th', key: '24th' },
  { value: '25th', key: '25th' },
  { value: '26th', key: '26th' },
  { value: '27th', key: '27th' },
  { value: '28th', key: '28th' },
  { value: 'last', key: 'last' },
];

export const monthlyOptions = [
  { value: 'day', key: 'day' },
  { value: 'first', key: 'first' },
  { value: 'second', key: 'second' },
  { value: 'third', key: 'third' },
  { value: 'fourth', key: 'fourth' },
  { value: 'last', key: 'last' },
];

export const daysInWeek = [
  { value: 'Sunday', key: 'Sunday' },
  { value: 'Monday', key: 'Monday' },
  { value: 'Tuesday', key: 'Tuesday' },
  { value: 'Wednesday', key: 'Wednesday' },
  { value: 'Thursday', key: 'Thursday' },
  { value: 'Friday', key: 'Friday' },
  { value: 'Saturday', key: 'Saturday' },
];

const allDisplayFields = [
  'service',
  'quantity',
  'rate',
  'amount',
  'description',
  'discount',
  'tax',
  'terms_conditions',
];

export const getFrequency = recurrence => {
  switch (recurrence.schedule.frequency) {
    case 'Daily':
      return `Daily every ${recurrence.schedule.frequency_daily_interval}
      day${recurrence.schedule.frequency_daily_interval > 1 ? 's' : ''}`;
    case 'Weekly':
      return `Weekly every ${recurrence.schedule.frequency_weekly_interval}
        week${recurrence.schedule.frequency_weekly_interval > 1 ? 's' : ''}
        on ${recurrence.schedule.frequency_weekly_weekday}`;
    case 'Monthly':
      if (recurrence.schedule.frequency_monthly_option === 'last') {
        return `Monthly on the last ${recurrence.schedule.frequency_monthly_nth_weekday}`;
      } else if (recurrence.schedule.frequency_monthly_option === 'day') {
        return `${
          recurrence.schedule.frequency_monthly_interval > 1
            ? 'Every ' + recurrence.schedule.frequency_monthly_interval + ' months'
            : 'Monthly'
        } on the ${recurrence.schedule.frequency_monthly_date} day`;
      } else {
        return `Monthly on the ${recurrence.schedule.frequency_monthly_option}
        ${recurrence.schedule.frequency_monthly_nth_weekday}`;
      }
    case 'Yearly':
      if (recurrence.schedule.frequency_yearly_date === 'last') {
        return `Yearly on the last day in ${recurrence.schedule.frequency_yearly_month}`;
      } else {
        return `Yearly every ${recurrence.schedule.frequency_yearly_month}
        ${recurrence.schedule.frequency_yearly_date}`;
      }
  }
};

export const getRemaining = recurrence => {
  let sent = get(recurrence, 'relationships.invoices.length');
  let max = get(recurrence, 'schedule.num_invoices') || sent + recurrence.remaining;
  if (max > 100) max = '99+';

  if (recurrence.remaining) {
    if (sent > 0 && !recurrence.started) {
      return `Ended (${sent}/${max} sent)`;
    } else {
      return `${sent}/${max} sent`;
    }
  } else {
    if (recurrence.remaining === 0) {
      if (recurrence.schedule.end_date) {
        let endDate = moment(recurrence.schedule.end_date).format('M/D/YYYY');
        return `Ended on ${endDate} (${sent}/${max} sent)`;
      } else {
        return `Ended (${sent}/${max} sent)`;
      }
    } else {
      return 'No end date';
    }
  }
};

export const getRemainingPayments = recurrence => {
  return `${recurrence.remaining > 100 ? '99+' : recurrence.remaining} payments remaining`;
};

export const getTerms = recurrence => {
  return recurrence.payment_terms === 'Due in fixed # of days'
    ? `After ${recurrence.terms} days`
    : recurrence.payment_terms;
};

export const getStatus = recurrence => {
  if (recurrence.next_occurrence && recurrence.started) {
    return 'Active';
  } else if (
    (!recurrence.next_occurrence && recurrence.started) ||
    (!recurrence.started && recurrence.relationships.invoices.length > 0)
  ) {
    return 'Ended';
  } else {
    return 'Not Scheduled';
  }
};

export const getBlankRecurrence = () => {
  return {
    description: '',
    start_date: moment().startOf('day'),
    status: 'not scheduled',
    end_option: 'Never',
    end_date: moment().startOf('day').add(1, 'months'),
    remaining_invoices: 1,
    num_invoices: 1,
    frequency: 'Monthly',
    frequency_yearly_month: 'January',
    frequency_yearly_date: '1st',
    frequency_monthly_option: 'day',
    frequency_monthly_date: '1st',
    frequency_monthly_nth_weekday: 'Sunday',
    frequency_monthly_interval: 1,
    frequency_weekly_interval: 1,
    frequency_weekly_weekday: 'Sunday',
    frequency_daily_interval: 1,
    payment_terms: 0,
    terms: 0,
  };
};

export const fetchRecurrence = recurrenceId => {
  return new Promise(resolve => {
    getRecurrence({ id: recurrenceId }).subscribe(recurringInvoice => {
      let invoice_template = get(recurringInvoice, 'invoice_template');
      let invoice = {
        client_notes: invoice_template.client_notes,
        display_fields: invoice_template.display_fields,
        invoice_line_items: [...invoice_template.invoice_line_items],
        relationships: { ...invoice_template.relationships },
        terms_conditions: invoice_template.terms_conditions,
        single_line_item: invoice_template.single_line_item,
        single_description: invoice_template.single_description,
        single_discount_amount: invoice_template.single_discount_amount,
        single_discount_percent: invoice_template.single_discount_percent,
      };

      if (!invoice.display_fields) {
        invoice.display_fields = allDisplayFields;
      }

      invoiceItems.processDiscount(invoice.invoice_line_items);

      let schedule = get(recurringInvoice, 'schedule');
      let recurrence = {
        started: recurringInvoice.started,
        next_occurrence: recurringInvoice.next_occurrence,
        id: recurringInvoice.id,
        description: recurringInvoice.description,
        relationships: recurringInvoice.relationships,
        terms: invoice_template.terms,
        payment_terms: calculatePaymentTerms(invoice_template.terms),
        start_date: moment(schedule.start_date).startOf('day'),
        end_option: schedule.end_option || 'Never',
        end_date: schedule.end_date
          ? moment(schedule.end_date).startOf('day')
          : moment(schedule.start_date).startOf('day').add(1, 'months'),
        num_invoices: schedule.num_invoices || 1,
        remaining: schedule.end_option === 'Never' ? 99 : recurringInvoice.remaining,
        frequency: schedule.frequency || 'Monthly',
        frequency_yearly_month: schedule.frequency_yearly_month || 'January',
        frequency_yearly_date: schedule.frequency_yearly_date || '1st',
        frequency_monthly_option: schedule.frequency_monthly_option || 'day',
        frequency_monthly_date: schedule.frequency_monthly_date || '1st',
        frequency_monthly_nth_weekday: schedule.frequency_monthly_nth_weekday || 'Sunday',
        frequency_monthly_interval: schedule.frequency_monthly_interval || 1,
        frequency_weekly_interval: schedule.frequency_weekly_interval || 1,
        frequency_weekly_weekday: schedule.frequency_weekly_weekday || 'Sunday',
        frequency_daily_interval: schedule.frequency_daily_interval || 1,
        recurring_payment_card_id: get(invoice_template, 'payment_details.card_id'),
        recurring_payment_terms: get(invoice_template, 'payment_details.terms'),
        recurring_payment_first_payment: get(invoice_template, 'payment_details.first_payment_occurrence'),
        third_party_data: invoice_template.third_party_data,
        auto_link_time: recurringInvoice.auto_link_time,
        include_spouse_name: recurringInvoice.include_spouse_name,
        is_business_client: recurringInvoice.is_business_client,
      };

      recurrence.remaining_invoices = calculateRemainingInvoices(recurrence, schedule);
      resolve({ invoice, recurrence });
    }, handleError);
  });
};

const calculatePaymentTerms = paymentTerms => {
  const defaults = [0, 10, 15, 30, 45, 60];
  return defaults.includes(paymentTerms) ? paymentTerms : -1;
};

const calculateRemainingInvoices = (recurrence, schedule) => {
  if (schedule.num_invoices) {
    return recurrence.num_invoices - recurrence.relationships.invoices.length;
  } else {
    return recurrence.remaining;
  }
};

const calculateInitialDate = (recurrence, startDate) => {
  let initial = moment(startDate);
  let initialDate;
  let today = recurrence.id ? initial.clone() : moment().startOf('day');
  switch (recurrence.frequency) {
    case 'Daily':
      if (initial.isBefore(today, 'day')) {
        initial = today;
      }
      break;
    case 'Weekly':
      if (initial.isBefore(today, 'day')) {
        initial = today;
      }
      let nextWeek =
        get(daysToNum, recurrence.frequency_weekly_weekday) - get(daysToNum, initial.format('dddd')) < 0 ? 7 : 0;
      initial.day(get(daysToNum, recurrence.frequency_weekly_weekday) + nextWeek);
      break;
    case 'Monthly':
      if (recurrence.frequency_monthly_option === 'day') {
        if (recurrence.frequency_monthly_date === 'last') {
          initialDate = moment(`${initial.month() + 1}-${initial.year()}`, 'M-YYYY').endOf('month');
        } else {
          if (initial.isBefore(today, 'day')) {
            initial = today;
          }
          initialDate = moment(
            `${initial.month() + 1}-${recurrence.frequency_monthly_date}-${initial.year()}`,
            'M-Do-YYYY'
          );
        }
        if (initialDate.isBefore(initial, 'day')) {
          initialDate.add(1, 'months');
        }
        initial = initialDate;
      } else if (recurrence.frequency_monthly_option === 'last') {
        initialDate = lastNthDayOfMonth(initial, get(daysToNum, recurrence.frequency_monthly_nth_weekday));
        if (initialDate.isBefore(initial, 'day')) {
          initialDate = lastNthDayOfMonth(
            initial.add(1, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday)
          );
        }
        initial = initialDate;
      } else {
        initialDate = nthDayOfMonth(
          initial,
          get(daysToNum, recurrence.frequency_monthly_nth_weekday),
          get(day, recurrence.frequency_monthly_option)
        );
        if (initialDate.isBefore(initial, 'day')) {
          initialDate = nthDayOfMonth(
            initial.add(1, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday),
            get(day, recurrence.frequency_monthly_option)
          );
        }
        initial = initialDate;
      }
      break;
    case 'Yearly':
      if (recurrence.frequency_yearly_date === 'last') {
        initialDate = moment(`${recurrence.frequency_yearly_month}-${initial.year()}`, 'MMMM-YYYY').endOf('month');
      } else {
        initialDate = moment(
          `${recurrence.frequency_yearly_month}-${recurrence.frequency_yearly_date}-${initial.year()}`,
          'MMMM-Do-YYYY'
        );
      }
      if (initialDate.isBefore(initial, 'day')) {
        initialDate.add(1, 'years');
      }
      initial = initialDate;
      break;
  }
  return initial;
};

export const calculateInitialInvoiceDate = recurrence => {
  return calculateInitialDate(recurrence, moment(recurrence.start_date).startOf('day'));
};

export const calculateInitialPaymentDate = recurrence => {
  return calculateInitialDate(recurrence, moment(recurrence.next_occurrence).startOf('day'));
};

export const calculateFinalInvoiceDate = (recurrence, initialInvoiceDate) => {
  switch (recurrence.end_option) {
    case 'On date':
      return calculateFinalInvoiceFromDate(recurrence, initialInvoiceDate.clone());
    case 'After':
      return calculateFinalInvoiceFromNumInvoices(recurrence, initialInvoiceDate.clone());
  }
};

const calculateFinalInvoiceFromDate = (recurrence, initialInvoiceDate) => {
  let end = moment(recurrence.end_date).startOf('day');
  let endDate;
  switch (recurrence.frequency) {
    case 'Daily':
      let daysOffEnd = offBy(initialInvoiceDate, end, 'days', recurrence.frequency_daily_interval);
      endDate = daysOffEnd === 0 ? end : end.subtract(daysOffEnd, 'days');
      break;
    case 'Weekly':
      endDate = end.clone().day(recurrence.frequency_weekly_weekday);
      let weeksOffEnd = offBy(initialInvoiceDate, endDate, 'weeks', recurrence.frequency_weekly_interval);
      if (weeksOffEnd !== 0) {
        endDate.subtract(weeksOffEnd, 'weeks');
      } else if (weeksOffEnd === 0 && end.day() < endDate.day()) {
        endDate.subtract(recurrence.frequency_weekly_interval, 'weeks');
      }
      end = endDate;
      break;
    case 'Monthly':
      let interval = parseInt(recurrence.frequency_monthly_interval);
      if (recurrence.frequency_monthly_option === 'day') {
        if (recurrence.frequency_monthly_option === 'day') {
          if (recurrence.frequency_monthly_date === 'last') {
            let monthOfYear = end.month();
            let dateOfMonth = end.date();
            if (monthOfYear === 1) {
              //February
              if (dateOfMonth < 28) {
                end.subtract(1, 'months');
              }
            } else {
              if (dateOfMonth < 29) {
                end.subtract(1, 'months');
              }
            }
            end.endOf('month');
            let monthsOff = offBy(initialInvoiceDate, end, 'months', interval);
            if (monthsOff !== 0) {
              end.subtract(monthsOff, 'months');
            }
          } else {
            endDate = moment(`${end.month() + 1}-${recurrence.frequency_monthly_date}-${end.year()}`, 'M-Do-YYYY');
            let monthsOff = offBy(initialInvoiceDate, endDate, 'months', interval);
            if (monthsOff !== 0) {
              endDate.subtract(monthsOff, 'months');
            } else if (monthsOff === 0 && end.date() < endDate.date()) {
              endDate.subtract(interval, 'months');
            }
            end = endDate;
          }
        }
      } else if (recurrence.frequency_monthly_option === 'last') {
        endDate = lastNthDayOfMonth(end, get(daysToNum, recurrence.frequency_monthly_nth_weekday));
        let monthsOff = offBy(
          initialInvoiceDate.clone().startOf('month'),
          endDate.clone().startOf('month'),
          'months',
          interval
        );

        if (monthsOff !== 0) {
          endDate = lastNthDayOfMonth(
            end.subtract(monthsOff, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday)
          );
        } else if (monthsOff === 0 && end.date() < endDate.date()) {
          endDate = lastNthDayOfMonth(
            end.subtract(interval, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday)
          );
        }
        end = endDate;
      } else {
        endDate = nthDayOfMonth(
          end,
          get(daysToNum, recurrence.frequency_monthly_nth_weekday),
          get(day, recurrence.frequency_monthly_option)
        );
        let monthsOff = offBy(
          initialInvoiceDate.clone().startOf('month'),
          endDate.clone().startOf('month'),
          'months',
          interval
        );
        if (monthsOff !== 0) {
          endDate = nthDayOfMonth(
            end.subtract(monthsOff, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday),
            get(day, recurrence.frequency_monthly_option)
          );
        } else if (monthsOff === 0 && end.date() < endDate.date()) {
          endDate = nthDayOfMonth(
            end.subtract(interval, 'months'),
            get(daysToNum, recurrence.frequency_monthly_nth_weekday),
            get(day, recurrence.frequency_monthly_option)
          );
        }
        end = endDate;
      }
      break;
    case 'Yearly':
      endDate;
      if (recurrence.frequency_yearly_date === 'last') {
        endDate = moment(`${recurrence.frequency_yearly_month}-${end.year()}`, 'MMMM-YYYY').endOf('month');
      } else {
        endDate = moment(
          `${recurrence.frequency_yearly_month}-${recurrence.frequency_yearly_date}-${end.year()}`,
          'MMMM-Do-YYYY'
        );
      }
      if (end.isBefore(endDate, 'day')) {
        endDate.subtract(1, 'years');
      }
      end = endDate;
      break;
  }
  if (moment(recurrence.end_date).isBefore(recurrence.start_date, 'day')) {
    endDate = moment(recurrence.end_date);
  } else {
    endDate = end;
  }
  return endDate;
};

const calculateFinalInvoiceFromNumInvoices = (recurrence, initialInvoiceDate) => {
  let numInvoices = parseInt(recurrence.remaining_invoices) + get(recurrence, 'relationships.invoices.length', 0) - 1;
  let endDate;
  switch (recurrence.frequency) {
    case 'Daily':
      endDate = initialInvoiceDate.add(recurrence.frequency_daily_interval * numInvoices, 'days');
      break;
    case 'Weekly':
      endDate = initialInvoiceDate.add(recurrence.frequency_weekly_interval * numInvoices, 'weeks');
      break;
    case 'Monthly':
      let finalMonth = initialInvoiceDate.add(recurrence.frequency_monthly_interval * numInvoices, 'months');
      if (recurrence.frequency_monthly_option === 'day') {
        if (recurrence.frequency_monthly_date === 'last') {
          finalMonth.endOf('month');
        }
        endDate = finalMonth;
      } else if (recurrence.frequency_monthly_option === 'last') {
        endDate = lastNthDayOfMonth(finalMonth, get(daysToNum, recurrence.frequency_monthly_nth_weekday));
      } else {
        endDate = nthDayOfMonth(
          finalMonth,
          get(daysToNum, recurrence.frequency_monthly_nth_weekday),
          get(day, recurrence.frequency_monthly_option)
        );
      }
      break;
    case 'Yearly':
      endDate = initialInvoiceDate.add(numInvoices, 'years');
      break;
  }
  return endDate;
};

const offBy = (startDate, endDate, frequency, interval) => {
  let diff = endDate.diff(startDate, frequency, true);
  interval = parseInt(interval);
  return diff % interval;
};

const lastNthDayOfMonth = (date, day) => {
  let result = date.clone().endOf('month');
  while (result.day() != day) {
    result.subtract(1, 'days');
  }
  return result;
};

const nthDayOfMonth = (startDate, dayOfWeek, weekNumber) => {
  var myMonth = startDate.clone().startOf('month');
  var firstDayOfWeek = myMonth.clone().weekday(dayOfWeek);
  if (firstDayOfWeek.month() != myMonth.month()) {
    weekNumber++;
  }
  return firstDayOfWeek.add(weekNumber - 1, 'weeks');
};

const daysToNum = { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 };

const day = { first: 1, second: 2, third: 3, fourth: 4 };

const prepareRecurrenceSchedule = recurrence => {
  let schedule = {
    start_date: moment(recurrence.start_date).format(ISODateFormat),
    end_option: recurrence.end_option,
    frequency: recurrence.frequency,
    cal_start_date: calculateInitialInvoiceDate(recurrence).format(ISODateFormat),
  };
  switch (recurrence.end_option) {
    case 'On date':
      schedule = { ...schedule, end_date: moment(recurrence.end_date).format(ISODateFormat) };
      break;
    case 'After':
      schedule = {
        ...schedule,
        num_invoices: parseInt(recurrence.remaining_invoices) + get(recurrence, 'relationships.invoices.length', 0),
      };
      break;
  }
  switch (recurrence.frequency) {
    case 'Daily':
      schedule = { ...schedule, frequency_daily_interval: parseInt(recurrence.frequency_daily_interval) };
      break;
    case 'Weekly':
      schedule = {
        ...schedule,
        frequency_weekly_interval: parseInt(recurrence.frequency_weekly_interval),
        frequency_weekly_weekday: recurrence.frequency_weekly_weekday,
      };
      break;
    case 'Monthly':
      schedule = {
        ...schedule,
        frequency_monthly_interval: parseInt(recurrence.frequency_monthly_interval),
        frequency_monthly_option: recurrence.frequency_monthly_option,
      };
      if (recurrence.frequency_monthly_option === 'day') {
        schedule = { ...schedule, frequency_monthly_date: recurrence.frequency_monthly_date };
      } else {
        schedule = { ...schedule, frequency_monthly_nth_weekday: recurrence.frequency_monthly_nth_weekday };
      }
      break;
    case 'Yearly':
      schedule = {
        ...schedule,
        frequency_yearly_month: recurrence.frequency_yearly_month,
        frequency_yearly_date: recurrence.frequency_yearly_date,
      };
      break;
  }
  return schedule;
};

export const prepareRecurrence = (invoice, recurrence, start, paymentDate, cardId) => {
  return {
    recurrences: {
      id: recurrence.id,
      started: start === undefined ? (recurrence.started === undefined ? false : recurrence.started) : start,
      description: recurrence.description,
      schedule: prepareRecurrenceSchedule(recurrence),
      auto_link_time: recurrence.auto_link_time,
      include_spouse_name: invoice.include_spouse_name,
      template: {
        client_notes: invoice.client_notes,
        discount_total: invoiceCalc.getDiscountTotal(invoice.invoice_line_items).toFixed(2),
        display_fields: invoice.display_fields,
        invoice_line_items: invoice.invoice_line_items.map((line_item, index) => {
          return {
            description: line_item.description ? line_item.description : '',
            discount_amount: !line_item.discountIsPercent ? (line_item.discount_amount * 1).toFixed(2) : null,
            discount_percent: line_item.discountIsPercent ? (line_item.discount_percent * 1).toFixed(2) : null,
            item_id: index,
            manual_adjustment_amount: line_item.manual_adjustment_amount,
            name: line_item.name || '',
            quantity: (line_item.quantity * 1).toFixed(2),
            relationships: line_item.relationships?.based_on
              ? { based_on: { id: line_item.relationships.based_on.id } }
              : undefined,
            rate_amount: (line_item.rate_amount * 1).toFixed(2),
            rate_type: line_item.rate_type,
            tax_rate_amount: null,
            tax_rate_percent: line_item.tax_rate_percent !== null ? (line_item.tax_rate_percent * 1).toFixed(4) : null,
            total_amount: invoiceItems.getSubTotal(line_item).toFixed(2),
            third_party_data: line_item.third_party_data,
          };
        }),
        invoice_subtotal: invoiceCalc.getSubTotal(invoice.invoice_line_items).toFixed(2),
        invoice_total: invoiceCalc.getTotal(invoice.invoice_line_items).toFixed(2),
        payment_details: cardId
          ? {
              card_id: cardId,
              terms: paymentDate === 'invoice date' ? 0 : parseInt(recurrence.terms),
            }
          : null,
        payment_terms:
          recurrence.payment_terms === -1
            ? `Net ${recurrence.terms}`
            : find(invoiceTerms, obj => obj.id === recurrence.payment_terms).name,
        relationships: {
          for: {
            id: invoice.relationships.for.id,
            name: invoice.relationships.for.name,
            type: 'client',
          },
        },
        single_description: invoice.single_description,
        single_discount_amount: invoice.single_discount_amount,
        single_discount_percent: invoice.single_discount_percent,
        single_line_item: invoice.single_line_item,
        tax_total: invoiceCalc.getTaxTotal(invoice.invoice_line_items).toFixed(2),
        terms: parseInt(recurrence.terms),
        terms_conditions: invoice.terms_conditions,
        third_party_data: recurrence.third_party_data,
      },
    },
  };
};
