import React, { useState, useEffect } from 'react';
import { CpSelectMultiPills } from 'canopy-styleguide!sofe';
import styles from './due-date/due-date.styles.css';

export const MultiSelectFilter = ({ keys, keyType, options, title, updateKeys }) => {
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    applyPropsToState();
  }, [keys]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'applyPropsToState'

  const applyPropsToState = () => {
    const itemsToSet = keys.reduce((acc, item) => {
      const filteredOptions = options.filter(optionItem => optionItem.id === item);
      acc.push(filteredOptions[0]);
      return acc;
    }, []);
    setSelected(itemsToSet);
  };

  const itemsChanged = items => {
    let allIndex = -1;
    const allIsSelected = items.some((item, index) => {
      allIndex = index;
      return item.id === 'all';
    });
    if (!items.length) {
      // nothing selected => select all
      applyItemsToState([options[0]]);
    } else if (allIsSelected) {
      if (items.length > 1 && allIndex === 0) {
        // an item has been selected after 'all', clear all and highlight that one
        applyItemsToState(items.slice(1));
      } else {
        applyItemsToState(items.filter(item => item.id === 'all'));
      }
    } else {
      applyItemsToState(items);
    }
    setSearchValue('');
  };

  const applyItemsToState = items => {
    setSelected(items);
    updateKeys(
      keyType,
      items.map(item => item.id)
    );
  };

  return (
    <div className={`${styles.flexContainer}`}>
      <div className={`${styles.labelContainer}`}>
        <div className={`${styles.label}`}>{title.capitalize()}</div>
      </div>
      <div style={{ width: '340px' }}>
        <CpSelectMultiPills
          data={options}
          onChange={itemsChanged}
          placeholder={`Search for ${title}`}
          renderPill={item => item.name}
          searchFilter={CpSelectMultiPills.filterAlpha(searchValue)}
          searchOnChange={setSearchValue}
          searchValue={searchValue}
          value={selected}
          triggerIsBlock
        />
      </div>
    </div>
  );
};
