import React, { useEffect } from 'react';
import { CpModal } from 'canopy-styleguide!sofe';

const CustomizeModal = ({ show, onClose, children }) => {
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => document.removeEventListener('keydown', escFunction, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'escFunction'

  const escFunction = event => {
    if (event.keyCode === 27) {
      onClose();
    }
  };

  return (
    <CpModal show={show} onClose={onClose} width={540}>
      {children &&
        React.Children.map(children, child => {
          const props = { closeModal: onClose, ...child.props };
          return React.cloneElement(child, props);
        })}
    </CpModal>
  );
};

export default CustomizeModal;
