// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoices-styles__billingNavContentNew--VKWH5 {
  margin-left: 268px !important;
  width: calc(100% - 268px) !important;
}

.invoices-styles__billingNavContentNew--VKWH5 {
  padding: 24px 16px;
}

.invoices-styles__dashboard--xgnBR {
  min-width: 1043px;
  max-width: 1360px;
  margin: 0 auto;
}

.invoices-styles__dashboardNew--d9Tjl {
  min-width: 963px;
  max-width: 1280px;
  margin: 0 auto;
}

.invoices-styles__invoiceHeader--hcpLc {
  min-height: 5.5rem;
  border-bottom: 0.1rem solid var(--cp-color-table-border);
}

.invoices-styles__invoiceActions--P_ke_ {
  margin-top: -6px;
}

.invoices-styles__invoiceTabs--jghw6 > div {
  display: inline;
  padding: 10px 16px;
  cursor: pointer;
}

.invoices-styles__invoiceTabs--jghw6 > div.invoices-styles__active--wsyou {
  font-weight: bold;
  border-bottom: 3px solid var(--cps-color-primary);
}

.invoices-styles__invoiceTabs--jghw6 a {
  color: var(--cps-color-monsoon);
  -webkit-text-decoration: none;
  text-decoration: none;
}

.invoices-styles__overdue--el1EM {
  color: var(--cps-color-warning) !important;
}

.invoices-styles__recurringActive--qyBXi {
  color: var(--cps-color-green) !important;
}
`, "",{"version":3,"sources":["webpack://./src/invoices/invoices.styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oCAAoC;AAEtC;;AAJA;EAGE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,wDAAwD;AAC1D;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iDAAiD;AACnD;;AAEA;EACE,+BAA+B;EAC/B,6BAAqB;EAArB,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".billingNavContentNew {\n  margin-left: 268px !important;\n  width: calc(100% - 268px) !important;\n  padding: 24px 16px;\n}\n\n.dashboard {\n  min-width: 1043px;\n  max-width: 1360px;\n  margin: 0 auto;\n}\n\n.dashboardNew {\n  min-width: 963px;\n  max-width: 1280px;\n  margin: 0 auto;\n}\n\n.invoiceHeader {\n  min-height: 5.5rem;\n  border-bottom: 0.1rem solid var(--cp-color-table-border);\n}\n\n.invoiceActions {\n  margin-top: -6px;\n}\n\n.invoiceTabs > div {\n  display: inline;\n  padding: 10px 16px;\n  cursor: pointer;\n}\n\n.invoiceTabs > div.active {\n  font-weight: bold;\n  border-bottom: 3px solid var(--cps-color-primary);\n}\n\n.invoiceTabs a {\n  color: var(--cps-color-monsoon);\n  text-decoration: none;\n}\n\n.overdue {\n  color: var(--cps-color-warning) !important;\n}\n\n.recurringActive {\n  color: var(--cps-color-green) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingNavContentNew": `invoices-styles__billingNavContentNew--VKWH5`,
	"dashboard": `invoices-styles__dashboard--xgnBR`,
	"dashboardNew": `invoices-styles__dashboardNew--d9Tjl`,
	"invoiceHeader": `invoices-styles__invoiceHeader--hcpLc`,
	"invoiceActions": `invoices-styles__invoiceActions--P_ke_`,
	"invoiceTabs": `invoices-styles__invoiceTabs--jghw6`,
	"active": `invoices-styles__active--wsyou`,
	"overdue": `invoices-styles__overdue--el1EM`,
	"recurringActive": `invoices-styles__recurringActive--qyBXi`
};
export default ___CSS_LOADER_EXPORT___;
