import React, { useCallback } from 'react';
import { CpTime } from 'canopy-styleguide!sofe';
import styles from './time-entry.styles.css';
import { secondsInOneDay } from './timer/timer.helper';

export default function TimeEntryDurationCalculator(props) {
  const { startTime, endTime } = props;

  const startTimeObj = {
    hour: startTime?.hour,
    minute: startTime?.minute,
    formattedTime: startTime?.toFormat('h:mma').toLowerCase(),
  };

  const endTimeObj = {
    hour: startTime?.hour,
    minute: startTime?.minute,
    formattedTime: endTime?.toFormat('h:mma').toLowerCase(),
  };

  const onStartTimeChanged = useCallback(
    time => {
      let startedAt = startTime?.set({ hour: time?.hour, minute: time?.minute });
      let duration = endTime?.diff(startedAt, 'seconds').seconds;
      let endedAt = endTime;
      if (duration > secondsInOneDay) {
        endedAt = endedAt?.minus({ days: 1 });
        duration = endedAt?.diff(startedAt, 'seconds').seconds;
      }
      if (duration <= 0) {
        endedAt = endedAt?.plus({ days: 1 });
        duration = endedAt?.diff(startedAt, 'seconds').seconds;
      }
      props.onStartTimeChanged(startedAt, endedAt, duration);
    },
    [startTime, endTime] // eslint-disable-line react-hooks/exhaustive-deps
  );
  // lint-TODO: React Hook useCallback has a missing dependency: 'props'. Either include it or remove the dependency array. However, 'props' will change when *any* prop changes, so the preferred fix is to destructure the 'props' object outside of the useCallback call and refer to those specific props inside useCallback

  const onEndTimeChanged = useCallback(
    time => {
      let endedAt = endTime?.set({ hour: time?.hour, minute: time?.minute });
      if (endedAt <= startTime) {
        endedAt = endedAt?.plus({ days: 1 });
      }
      let duration = endedAt?.diff(startTime, 'seconds').seconds;
      if (duration > secondsInOneDay) {
        endedAt = endedAt?.minus({ days: 1 });
        duration -= secondsInOneDay;
      }
      props.onEndTimeChanged(endedAt, duration);
    },
    [startTime, endTime] // eslint-disable-line react-hooks/exhaustive-deps
  );
  // lint-TODO: React Hook useCallback has a missing dependency: 'props'. Either include it or remove the dependency array. However, 'props' will change when *any* prop changes, so the preferred fix is to destructure the 'props' object outside of the useCallback call and refer to those specific props inside useCallback

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={`cps-form-group ${styles.editLabelAndInput}`}>
          <div className={styles.editLabel}>
            <label htmlFor="startTimeInput">
              Start time
              <span className="cps-color-primary">*</span>
            </label>
          </div>
          <div className={`cps-form-group ${styles.dateTimeInput} cp-mb-0`}>
            <CpTime
              triggerIsBlock
              style={{ width: '220px' }}
              value={startTimeObj}
              onChange={onStartTimeChanged}
              contentWidth="block"
              clearable={false}
            />
          </div>
        </div>
        <div className={`cps-form-group ${styles.editLabelAndInput}`}>
          <div className={styles.editLabel}>
            <label htmlFor="endTimeInput">
              End time
              <span className="cps-color-primary">*</span>
            </label>
          </div>
          <div className={`cps-form-group ${styles.dateTimeInput} cp-mb-0`}>
            <CpTime
              triggerIsBlock
              style={{ width: '220px' }}
              value={endTimeObj}
              onChange={onEndTimeChanged}
              contentWidth="block"
              clearable={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}
