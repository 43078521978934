import React, { useEffect, useRef, useContext, useCallback } from 'react';
import { featureEnabled } from 'feature-toggles!sofe';
import { useWithUserAndTenant } from 'cp-client-auth!sofe';
import { CpButton } from 'canopy-styleguide!sofe';
import { InvoiceHistory } from './invoice-history.component';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { modes } from 'src/invoices/invoices.helper';
import {
  createWebviewerOptions,
  disableElements,
  customizeHotKeys,
  handlePDFTronErrors,
} from 'src/common/components/webviewer/webviewer.utils';
import { getInvoicePDF } from 'src/resources/invoices.resources';
import { handleError } from 'src/common/handle-error.helper';

export const InvoicePreviewer = ({ fromRecurrence, onClose, setFileViewerError }) => {
  const { mode, invoice, invoicePreview, webViewerInstance, actions } = useContext(InvoiceContext);
  const [user] = useWithUserAndTenant();
  const isMounted = useRef();
  const isClient = user?.role === 'Client';
  const filename = invoice?.invoiceNumber ? `Invoice #${invoice.invoiceNumber}.pdf` : 'invoice.pdf';

  const viewerRef = useRef(null);

  const catchFileViewerError = useCallback(e => {
    setFileViewerError(true);
    handlePDFTronErrors(e);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'setFileViewerError'

  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;

    if (featureEnabled('toggle_pdftron_upgrade')) {
      window.WebViewer.WebComponent(createWebviewerOptions(), viewerRef.current)
        .then(instance => {
          disableElements(instance);
          customizeHotKeys(instance);
          actions.initializeWebViewer(instance);
        })
        .catch(e => catchFileViewerError(e));
    } else {
      window
        .WebViewer(createWebviewerOptions(), viewerRef.current)
        .then(instance => {
          disableElements(instance);
          customizeHotKeys(instance);
          actions.initializeWebViewer(instance);
        })
        .catch(e => catchFileViewerError(e));
    }

    return () => actions.initializeWebViewer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'actions' and 'catchFileViewerError'

  useEffect(() => {
    if (!invoicePreview || !webViewerInstance) return;

    try {
      webViewerInstance.UI.loadDocument(invoicePreview, { filename });
    } catch (error) {
      catchFileViewerError(error);
    }
  }, [invoicePreview, webViewerInstance]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'catchFileViewerError' and 'filename'

  useEffect(() => {
    if (!invoice.id || !webViewerInstance || mode === modes.preview) return;

    const subscription = getInvoicePDF(invoice.id, true).subscribe(response => {
      try {
        webViewerInstance.UI.loadDocument(response, { filename });
      } catch (error) {
        catchFileViewerError(error);
      }
    }, handleError);

    return () => subscription.unsubscribe();
  }, [invoice, webViewerInstance]); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'catchFileViewerError', 'filename', and 'mode'
  return (
    <div>
      {mode === modes.preview && (
        <CpButton
          btnType="tertiary"
          className="cp-mb-8"
          icon="arrow-line-left"
          onClick={() => actions.modeChange(invoice.id && !invoice.draft ? modes.edit : modes.create)}
        >
          Back to edit
        </CpButton>
      )}
      {mode === modes.view && fromRecurrence && (
        <CpButton btnType="tertiary" className="cp-mb-8" icon="arrow-line-left" onClick={actions.returnToRecurrence}>
          Back to recurrence
        </CpButton>
      )}
      <div style={{ height: '1024px' }} ref={viewerRef}></div>
      {!isClient && invoice.id && mode == modes.view && invoice.history && (
        <InvoiceHistory invoice={invoice} onClose={onClose} />
      )}
    </div>
  );
};
