import React, { useState, useEffect } from 'react';
import { CpButton, CpInput } from 'canopy-styleguide!sofe';
import { ServiceSelector } from 'src/invoices/components/invoice-builder/form-components/service-selector.component';
import { InputGroup } from 'src/common/components/input_group/input-group.component';
import { decimalNumberFilter } from 'src/billing-helpers';
import { toCurrency, toNumber } from 'src/invoices/invoices.helper';
import { removeCommas } from 'src/invoices_v2/line-items/calculations.helpers';

import styles from './credit-builder.styles.css';

export const LineItem = ({
  lineItem,
  disabled,
  index,
  updateLineItem,
  removeLineItem,
  categorizedServices,
  allServiceItems,
  qboCategories,
}) => {
  const [internalLineItem, setInternalLineItem] = useState(lineItem);
  const [highlightField, setHighlightField] = useState(null);

  useEffect(() => {
    setInternalLineItem({
      service: lineItem.service,
      description: lineItem.description,
      quantity: toNumber(lineItem.quantity),
      rate: toNumber(lineItem.rate, 2, 4),
      taxRate: toNumber(lineItem.taxRate, 0, 4),
      id: lineItem.id,
      ...(lineItem.service.id && {
        relationships: { based_on: { id: lineItem.service.id, name: lineItem.name, type: 'item' } },
      }),
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // lint-TODO: has missing dependencies: 'lineItem.description', 'lineItem.id', 'lineItem.name', 'lineItem.quantity', 'lineItem.rate', 'lineItem.service', and 'lineItem.taxRate'

  useEffect(() => {
    if (!highlightField) return;

    highlightField.select();
    setHighlightField(null);
  }, [highlightField]);

  const updateServiceItem = serviceItem => {
    if (!serviceItem || (serviceItem && serviceItem === internalLineItem.service?.name)) return;

    let item = {
      service: serviceItem.id ? { id: serviceItem.id, name: serviceItem.name } : { name: serviceItem },
      description: serviceItem.description || internalLineItem.description,
      quantity: internalLineItem.quantity || 1,
      serviceRateType: serviceItem.rate_type,
      rate: serviceItem.amount || 0,
      taxRate: serviceItem.tax_rate_percent || 0,
      relationships: {
        based_on: {
          type: 'item',
          id: parseInt(serviceItem.id),
          name: serviceItem.name,
          rate: parseFloat(serviceItem.amount),
        },
      },
      id: internalLineItem.id,
    };
    setInternalLineItem(item);
    updateLineItem(index, item);
  };

  return (
    <tr>
      <td>
        <div className={styles.dataCell} style={{ width: '150px' }}>
          <ServiceSelector
            value={internalLineItem.service}
            update={updateServiceItem}
            disabled={disabled}
            categorizedServices={categorizedServices}
            allServiceItems={allServiceItems}
            qboCategories={qboCategories}
          />
        </div>
      </td>
      <td>
        <div className={styles.dataCell}>
          <CpInput
            className={styles.descriptionCell}
            placeholder="Add description"
            maxLength="500"
            value={internalLineItem.description}
            onChange={description => setInternalLineItem({ ...internalLineItem, description })}
            onBlur={() => updateLineItem(index, internalLineItem)}
          />
        </div>
      </td>
      <td>
        <div className={styles.dataCell} style={{ width: '90px' }}>
          <CpInput
            placeholder=""
            disabled={disabled}
            maxLength="7"
            value={internalLineItem.quantity.toString()}
            onFocus={e => {
              setInternalLineItem({
                ...internalLineItem,
                quantity: decimalNumberFilter(e.target.value, 2, false, false),
              });
              setHighlightField(e.target);
            }}
            onChange={value =>
              setInternalLineItem({ ...internalLineItem, quantity: decimalNumberFilter(value, 2, false, false) })
            }
            onBlur={() => updateLineItem(index, internalLineItem)}
          />
        </div>
      </td>
      <td>
        <div className={styles.dataCell} style={{ width: '120px' }}>
          <InputGroup
            addon={<span>$</span>}
            style={{ zIndex: 'inherit' }}
            maxLength="9"
            disabled={disabled}
            value={internalLineItem.rate}
            onFocus={e => {
              setInternalLineItem({
                ...internalLineItem,
                rate: decimalNumberFilter(e.target.value, 4, false, true),
              });
              setHighlightField(e.target);
            }}
            onChange={value =>
              setInternalLineItem({
                ...internalLineItem,
                rate: decimalNumberFilter(value, 4, false, true),
              })
            }
            onBlur={() => updateLineItem(index, internalLineItem)}
          />
        </div>
      </td>
      <td>
        <div className={styles.dataCell} style={{ width: '120px' }}>
          <InputGroup
            addon={<span>%</span>}
            side="right"
            style={{ zIndex: 'inherit' }}
            maxLength="9"
            disabled={disabled}
            value={internalLineItem.taxRate?.toString() || ''}
            onFocus={e => {
              setInternalLineItem({
                ...internalLineItem,
                taxRate: decimalNumberFilter(e.target.value, 4, false, false),
              });
              setHighlightField(e.target);
            }}
            onChange={value =>
              setInternalLineItem({
                ...internalLineItem,
                taxRate: decimalNumberFilter(value, 4, false, false),
              })
            }
            onBlur={() => updateLineItem(index, internalLineItem)}
          />
        </div>
      </td>
      <td>
        <div className={styles.dataCell}>
          {toCurrency(internalLineItem.quantity * removeCommas(internalLineItem.rate))}
        </div>
      </td>
      {!disabled && (
        <td>
          <CpButton icon="close-large" aria-label="Close" onClick={() => removeLineItem(index)} />
        </td>
      )}
    </tr>
  );
};
