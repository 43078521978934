import React, { useMemo, useContext, useEffect, useState } from 'react';
import { featureEnabled } from 'feature-toggles!sofe';
import { CpDropdown, CpButton, CpIcon, CpTooltip } from 'canopy-styleguide!sofe';
import { useHasAccess, useWithUserAndTenant } from 'cp-client-auth!sofe';
import { handleError } from 'src/common/handle-error.helper';
import { InvoiceContext } from 'src/invoices/invoice-context';
import { invoiceCalc, invoiceTypes } from 'src/invoices/invoices.helper';
import { getWhiteLabelSettings } from 'src/resources/invoices.resources';

export const InvoiceOverlayActions = ({ onLinkedTime, onClose, onConfigure, onRequestDelete, hasCanopyPayments }) => {
  const { invoice, recurrence, invoiceType, mode, actions, errors, disableActions } = useContext(InvoiceContext);
  const canCreateEditInvoice = useHasAccess('billing_invoices_create_edit');
  const canCreatePayment = useHasAccess('billing_payments_create_edit');
  const canArchiveAndDelete = useHasAccess('billing_archive_delete');
  const [user] = useWithUserAndTenant();
  const [showClientBillingTab, setShowClientBillingTab] = useState(true);

  const isClient = user?.role === 'Client';

  const isRecurringInvoice = invoiceType === invoiceTypes.recurring;
  const invoiceIsValid = errors?.validationErrors?.length === 0;

  const isPaid = useMemo(() => {
    return invoiceCalc.getBalanceDue(invoice) === 0;
  }, [invoice]);

  useEffect(() => {
    const subscription = getWhiteLabelSettings().subscribe(response => {
      setShowClientBillingTab(response?.show_cp_billing);
    }, handleError);
    return () => subscription.unsubscribe();
  }, []);

  const canEditRecurrence = recurrence.status === 'Not Scheduled';
  const recurrenceActive = recurrence.status === 'Active';

  switch (mode) {
    case 'create':
      return (
        <>
          {canCreateEditInvoice && !!invoice.lineItems.hidden.length && (
            <CpTooltip
              text={
                <div className="cp-text-center">
                  Manage time linked <br /> from the WIP report
                </div>
              }>
              <CpButton aria-label="Time Entries" className="cp-mr-8" icon="misc-timer" onClick={onLinkedTime} />
            </CpTooltip>
          )}
          {canCreateEditInvoice && canArchiveAndDelete && (
            <CpTooltip text="Delete">
              <CpButton aria-label="Delete" className="cp-mr-8" icon="crud-trash-large" onClick={actions.delete} />
            </CpTooltip>
          )}
          <CpTooltip text="Layout">
            <CpButton aria-label="Layout" className="cp-mr-8" icon="misc-gear" onClick={onConfigure} />
          </CpTooltip>
          {canCreateEditInvoice && (
            <CpButton
              btnType="secondary"
              className="cp-mr-16"
              onClick={() => {
                actions.save(true, false);
              }}
              disabled={!invoiceIsValid || disableActions}>
              Save as draft
            </CpButton>
          )}
          {canCreateEditInvoice && (
            <CpButton
              btnType="primary"
              onClick={() => {
                if (isRecurringInvoice) {
                  actions.start();
                } else {
                  actions.preview();
                }
              }}
              disabled={!invoiceIsValid}
              showLoader={disableActions}>
              {isRecurringInvoice ? 'Start' : 'Preview'}
            </CpButton>
          )}
        </>
      );
    case 'edit':
      return isRecurringInvoice ? (
        <>
          {canCreateEditInvoice && canEditRecurrence && (
            <CpTooltip text="Save">
              <CpButton
                aria-label="Save"
                className="cp-mr-8"
                onClick={() => {
                  actions.save(false, false);
                }}
                disabled={!invoiceIsValid || disableActions}
                icon="crud-floppy-disk"
              />
            </CpTooltip>
          )}
          {canCreateEditInvoice && canArchiveAndDelete && (
            <CpTooltip text="Delete">
              <CpButton
                aria-label="Delete"
                className="cp-mr-8"
                icon="crud-trash-large"
                disabled={disableActions}
                onClick={() => {
                  onRequestDelete();
                }}
              />
            </CpTooltip>
          )}
          {canCreateEditInvoice && (
            <CpTooltip text="Duplicate">
              <CpButton aria-label="Duplicate" className="cp-mr-8" icon="crud-duplicate" onClick={actions.duplicate} />
            </CpTooltip>
          )}
          <CpTooltip text="Layout">
            <CpButton aria-label="Layout" className="cp-mr-8" icon="misc-gear" onClick={onConfigure} />
          </CpTooltip>
          {recurrenceActive ? (
            <>
              {canCreateEditInvoice && (
                <CpButton
                  btnType="flat"
                  onClick={() => {
                    actions.stop();
                  }}
                  disabled={disableActions}>
                  End recurrence
                </CpButton>
              )}
              {canCreateEditInvoice && (
                <CpButton
                  btnType="primary"
                  onClick={() => {
                    actions.save(false);
                  }}
                  disabled={!invoiceIsValid || disableActions}>
                  Save
                </CpButton>
              )}
            </>
          ) : (
            canCreateEditInvoice && (
              <CpButton
                btnType="primary"
                onClick={() => {
                  actions.start();
                }}
                disabled={!canEditRecurrence || !invoiceIsValid}
                showLoader={disableActions}>
                Start
              </CpButton>
            )
          )}
        </>
      ) : canCreateEditInvoice ? (
        <>
          {!!invoice.lineItems.hidden.length && (
            <CpTooltip
              text={
                <div className="cp-text-center">
                  Manage time linked <br /> from the WIP report
                </div>
              }>
              <CpButton aria-label="Time Entries" className="cp-mr-8" icon="misc-timer" onClick={onLinkedTime} />
            </CpTooltip>
          )}
          <CpTooltip text="Layout">
            <CpButton aria-label="Layout" className="cp-mr-8" icon="misc-gear" onClick={onConfigure} />
          </CpTooltip>
          <CpButton btnType="primary" onClick={actions.preview} disabled={!invoiceIsValid} showLoader={disableActions}>
            Preview changes
          </CpButton>
        </>
      ) : null;
    case 'preview':
      return isRecurringInvoice ? (
        <div></div>
      ) : (
        <>
          {canCreateEditInvoice && (
            <CpTooltip text={invoice.id && !invoice.draft ? 'Save' : 'Create and save'}>
              <CpButton
                aria-label="Save"
                className="cp-mr-8"
                onClick={() => {
                  actions.save(false);
                }}
                disabled={disableActions}
                icon="crud-floppy-disk"
              />
            </CpTooltip>
          )}
          {canCreateEditInvoice && (
            <CpTooltip text={invoice.id && !invoice.draft ? 'Download' : 'Create and download'}>
              <CpButton
                aria-label="Download"
                className="cp-mr-8"
                onClick={() => actions.download(true)}
                disabled={disableActions}
                icon="af-open-down"
              />
            </CpTooltip>
          )}
          {canCreateEditInvoice && (
            <CpTooltip text={invoice.id && !invoice.draft ? 'Print' : 'Create and print'}>
              <CpButton
                aria-label="Print"
                className="cp-mr-8"
                onClick={() => actions.print(true)}
                disabled={disableActions}
                icon="misc-printer"
              />
            </CpTooltip>
          )}
          {canCreateEditInvoice && !invoice.archived && (
            <CpTooltip
              disabled={showClientBillingTab}
              text="Go to Settings > Custom Branding to enable Billing in the Client Portal.">
              <span>
                <CpButton
                  btnType="primary"
                  onClick={() => {
                    actions.send();
                  }}
                  disabled={disableActions || !showClientBillingTab}>
                  Send
                </CpButton>
              </span>
            </CpTooltip>
          )}
        </>
      );
    case 'view':
      return isRecurringInvoice || !invoice.id ? (
        <div></div>
      ) : isClient ? (
        <div>
          {invoice.status !== 'paid' && hasCanopyPayments && (
            <CpButton btnType="primary" className="cp-mr-16" onClick={actions.addPayment}>
              Add payment
            </CpButton>
          )}
          <CpTooltip text="Download">
            <CpButton
              aria-label="Download"
              className="cp-mr-8"
              icon="af-open-down"
              onClick={() => actions.download()}
            />
          </CpTooltip>
          <CpTooltip text="Print">
            <CpButton aria-label="Print" icon="misc-printer" onClick={() => actions.print()} />
          </CpTooltip>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {canCreateEditInvoice && !!invoice.lineItems.hidden.length && (
            <CpTooltip
              text={
                <div className="cp-text-center">
                  Manage time linked <br /> from the WIP report
                </div>
              }>
              <CpButton aria-label="Time Entries" className="cp-mr-8" icon="misc-timer" onClick={onLinkedTime} />
            </CpTooltip>
          )}
          <CpTooltip text="Download">
            <CpButton
              aria-label="Download"
              className="cp-mr-8"
              icon="af-open-down"
              onClick={() => actions.download()}
            />
          </CpTooltip>
          <CpTooltip text="Print">
            <CpButton aria-label="Print" className="cp-mr-8" icon="misc-printer" onClick={() => actions.print()} />
          </CpTooltip>
          {(canCreateEditInvoice || (canArchiveAndDelete && !isPaid && !invoice.hasRecurringPayment)) && (
            <CpDropdown
              renderTrigger={({ toggle }) => (
                <CpButton aria-label="Menu" className="cp-mr-8" icon="misc-kabob" onClick={toggle} />
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  {((isPaid && !invoice.payments) ||
                    (!isPaid && !invoice.hasRecurringPayment && canCreateEditInvoice)) && (
                    <a
                      {...(featureEnabled('toggle_gs_invoice_refactor')
                        ? {
                            href: `/#/billing/invoice/${isRecurringInvoice ? 'recurring' : 'single'}/editor/${
                              invoice.id
                            }`,
                            onClick: () => {
                              onClose?.();
                            },
                          }
                        : {
                            onClick: () => actions.modeChange('edit'),
                          })}>
                      <CpIcon name="crud-pencil" className="cp-mr-8" />
                      Edit
                    </a>
                  )}
                  {canCreateEditInvoice && (
                    <a onClick={actions.duplicate}>
                      <CpIcon name="crud-duplicate" className="cp-mr-8" />
                      Duplicate
                    </a>
                  )}
                  {!invoice.archived && !invoice.hasRecurringPayment && canArchiveAndDelete && (
                    <a onClick={actions.archive}>
                      <CpIcon name="crud-archive" className="cp-mr-8" />
                      Archive
                    </a>
                  )}
                  {invoice.archived && canArchiveAndDelete && (
                    <a onClick={actions.restore}>
                      <CpIcon name="af-undo" className="cp-mr-8" />
                      Move to active
                    </a>
                  )}
                  {((invoice.archived && !invoice.hasRecurringPayment && canArchiveAndDelete) ||
                    (invoice.archived && !isPaid && !invoice.hasRecurringPayment && canArchiveAndDelete)) && (
                    <a onClick={onRequestDelete}>
                      <CpIcon name="crud-trash-large" className="cp-mr-8" />
                      Delete
                    </a>
                  )}
                  {!isRecurringInvoice && isPaid && invoice.payments && (
                    <a
                      href={`/#/billing/invoice/single/editor/${invoice.id}?viewOnly=true`}
                      onClick={() => {
                        onClose?.();
                      }}>
                      <CpIcon name="communication-email-template" className="cp-mr-8" />
                      View Details
                    </a>
                  )}
                </div>
              )}
            />
          )}
          {!invoice.archived && (
            <>
              {invoice.status !== 'paid' && canCreatePayment && !isPaid && (
                <CpButton btnType="secondary" className="cp-mr-16" onClick={actions.addPayment}>
                  Add payment
                </CpButton>
              )}
              {canCreateEditInvoice && (
                <CpTooltip
                  disabled={showClientBillingTab}
                  text="Go to Settings > Custom Branding to enable Billing in the Client Portal.">
                  <span>
                    <CpButton
                      btnType="primary"
                      onClick={() => {
                        actions.send();
                      }}
                      disabled={disableActions || !showClientBillingTab}>
                      {invoice.sentAt ? 'Resend' : 'Send'}
                    </CpButton>
                  </span>
                </CpTooltip>
              )}
            </>
          )}
        </div>
      );
    case 'viewOnly':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CpTooltip text="Download">
            <CpButton
              aria-label="Download"
              className="cp-mr-8"
              icon="af-open-down"
              onClick={() => actions.download()}
            />
          </CpTooltip>
          <CpTooltip text="Print">
            <CpButton aria-label="Print" icon="misc-printer" onClick={() => actions.print()} />
          </CpTooltip>
        </div>
      );
    default:
      return null;
  }
};
